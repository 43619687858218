import React, { useEffect, useState } from "react";
import AxiosClient from "../../client/client";
import { Link, useParams } from "react-router-dom";
import DOMPurify from "dompurify";
import VideoCardList from "../videoCards/videoCardList/VideoCardList";
import { useNavigate } from "react-router-dom";
import { nanoid } from "nanoid";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Tooltip from "react-bootstrap/Tooltip";
import { PuffLoader } from "react-spinners";
import {
  Cart2,
  CheckCircle,
  DashCircle,
  PlusCircle,
} from "react-bootstrap-icons";
import { Button, Card, Col, Container, Row } from "react-bootstrap";
import BannerPubblCashback from "../../assets/BannerPubblCashback.png";

const NodeViewWeb = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const client = new AxiosClient();

  const [content, setContent] = useState([]);
  const [idRamo1, setIdRamo1] = useState([]);
  const [idRamo2, setIdRamo2] = useState([]);
  const [idRamo3, setIdRamo3] = useState([]);
  const [idRamo4, setIdRamo4] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  const [sottoCorsi, setSottoCorsi] = useState([]);

  const [hasContent, setHasContent] = useState(false);

  const handleContentChange = (contentExists) => {
    setHasContent(contentExists);
  };

  const getCont = async () => {
    try {
      const response = await client.get(`/webinar/${id}`);
      setContent(response);
    } catch (error) {
      // Gestisci l'errore qui, ad esempio reindirizzando alla pagina "Not Found"
      //console.error("Errore nella richiesta GET:", error);
      if (error.response && error.response.status === 500) {
        navigate("/pagina-non-trovata"); // Reindirizza alla pagina "Not Found"
      } else {
        // Altrimenti, gestisci l'errore in base alle tue esigenze
        // Potresti mostrare un messaggio all'utente, per esempio
        console.error("Errore sconosciuto:", error);
        // Oppure potresti fare altro
      }
    }
  };

  useEffect(() => {
    getCont();
  }, [id]);

  const ramo1 = content.webinar?.ramo1;
  const ramo2 = content.webinar?.ramo2;
  const ramo3 = content.webinar?.ramo3;
  const ramo4 = content.webinar?.ramo4;

  const getIdRamo = async (ramo, setIdRamo) => {
    if (ramo) {
      const response = await client.get(`/webinarByRamo/${ramo}`);
      setIdRamo(response);
    }
  };

  useEffect(() => {
    getIdRamo(ramo1, setIdRamo1);
  }, [ramo1]);

  useEffect(() => {
    getIdRamo(ramo2, setIdRamo2);
  }, [ramo2]);

  useEffect(() => {
    getIdRamo(ramo3, setIdRamo3);
  }, [ramo3]);

  useEffect(() => {
    getIdRamo(ramo4, setIdRamo4);
  }, [ramo4]);

  //------------------------------------------------------------------ AUTORIZZAZIONE VISTA VIDEO
  // creo 2 variabili per aver chiaro i 2 array da confrontare
  //   const corsiAccount = session.course;
  //   const corsiVariabile = [
  //     content.course?.ramo1,
  //     content.course?.ramo2,
  //     content.course?.ramo3,
  //     content.course?.ramo4,
  //   ];
  // se il confronto è positivo la variabile authorized diventa true altrimenti false
  //   let purchase = corsiVariabile.some((corsi) => corsiAccount.includes(corsi));

  //   let inCarrello = false;

  // Verifica se session.carrello è definito come un array e content.course?.titolo è definito
  //   if (Array.isArray(session.carrello) && content.course?.titolo) {
  //     // Crea un array contenente solo i titoli dei corsi nel carrello
  //     const carrelloAccount = session.carrello.map((item) => item.articolo);

  //     // Verifica se il titolo del corso è presente nell'array carrelloAccount
  //     inCarrello = carrelloAccount.includes(content.course?.titolo);
  //   }

  //   let corsiVariabile2 = [];
  //   if (content.course?.titolo === content.course?.ramo1) {
  //     corsiVariabile2 = [
  //       content.course?.ramo2,
  //       content.course?.ramo3,
  //       content.course?.ramo4,
  //     ];
  //   } else if (content.course?.titolo === content.course?.ramo2) {
  //     corsiVariabile2 = [content.course?.ramo3, content.course?.ramo4];
  //   } else if (content.course?.titolo === content.course?.ramo3) {
  //     corsiVariabile2 = [content.course?.ramo4];
  //   }

  //   const carrelloAccount = session.carrello.map((item) => item.articolo);

  //   let corsoUpInCarrello = corsiVariabile2.some((corsi) =>
  //     carrelloAccount.includes(corsi)
  //   );

  //----------------------------------------------------------------------------------------------

  const sanitizedHTML = DOMPurify.sanitize(content.webinar?.descrizione);

  const handleRamo0Click = () => {
    // Naviga alla destinazione con l'id del corso
    navigate(`/webinar`);
  };

  const handleRamo1Click = () => {
    // Naviga alla destinazione con l'id del corso
    navigate(`/webinar/${idRamo1.courseId}`);
  };

  const handleRamo2Click = () => {
    // Naviga alla destinazione con l'id del corso
    navigate(`/webinar/${idRamo2.courseId}`);
  };

  const handleRamo3Click = () => {
    // Naviga alla destinazione con l'id del corso
    navigate(`/webinar/${idRamo3.courseId}`);
  };

  let numeroRamo = "2";
  if (!content.webinar?.ramo2) {
    numeroRamo = "2";
  } else if (!content.webinar?.ramo3) {
    numeroRamo = "3";
  } else if (!content.webinar?.ramo4) {
    numeroRamo = "4";
  }

  //get ramo
  const getRamo = async () => {
    try {
      const response = await client.get(
        `/webinars/ramo${numeroRamo}/${content.webinar?.titolo}`
      );
      setSottoCorsi(response);
    } catch (e) {
      console.log(e);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    if (content.webinar?.titolo) {
      getRamo();
    }
  }, [numeroRamo, content.webinar?.titolo]);

  if (isLoading || !content.webinar) {
    return (
      <div className="alert-container">
        <PuffLoader color="#ec800f" size={200} />
      </div>
    ); // Mostra un messaggio di caricamento
  }

  //   const handleAddCarrello = async (e) => {
  //     e.preventDefault();

  //     try {
  //       const response = await client.patch(
  //         `/account/updateCarrello/${session.id}`,
  //         addCarrello.carrello,
  //         {
  //           headers: {
  //             "Content-Type": "application/json",
  //           },
  //         }
  //       );
  //       setBooleana(true);
  //       //getUploadToken();

  //       if (response.statusCode === 200) {
  //         console.log("Dati modificati con successo");
  //         // Aggiungi qui la logica per gestire la risposta positiva dal server
  //       } else {
  //         console.error("Errore durante la modifica dei dati");
  //         // Aggiungi qui la logica per gestire la risposta negativa dal server
  //       }
  //     } catch (e) {
  //       console.error("Errore nella richiesta al server", e);
  //       // Aggiungi qui la logica per gestire gli errori durante la richiesta al server
  //     }
  //   };

  //   const handleRemoveFromCart = async (course) => {
  //     try {
  //       const response = await client.patch(
  //         `/account/removefrom/${session.id}`,
  //         addCarrello.carrello,
  //         {
  //           headers: {
  //             "Content-Type": "application/json",
  //           },
  //         }
  //       );
  //       setBooleana(true);

  //       if (response.statusCode === 200) {
  //         console.log("Elemento rimosso dal carrello con successo");
  //         // Aggiungi qui la logica per gestire la risposta positiva dal server
  //       } else {
  //         console.error("Errore durante la rimozione dall carrello");
  //         // Aggiungi qui la logica per gestire la risposta negativa dal server
  //       }
  //     } catch (e) {
  //       console.error("Errore nella richiesta al server", e);
  //       // Aggiungi qui la logica per gestire gli errori durante la richiesta al server
  //     }
  //   };

  //   let numeroRamo = "2";
  //   if (!content.course?.ramo2) {
  //     numeroRamo = "2";
  //   } else if (!content.course?.ramo3) {
  //     numeroRamo = "3";
  //   } else if (!content.course?.ramo4) {
  //     numeroRamo = "4";
  //   }

  //   //get ramo2
  //   const getRamo = async () => {
  //     try {
  //       const response = await client.get(
  //         `/webinars/ramo${numeroRamo}/${content.webinar?.titolo}`
  //       );
  //       setSottoCorsi(response);
  //     } catch (e) {
  //       console.log(e);
  //     }
  //   };

  //   useEffect(() => {
  //     getRamo();
  //   }, [numeroRamo, content.course?.titolo]);

  //   // Controlla se almeno uno dei rami superiori del corso è nel carrello
  //   const isSuperiorCourseInCart = (course) => {
  //     const superiorCourses = [
  //       course?.ramo1,
  //       course?.ramo2,
  //       course?.ramo3,
  //       course?.ramo4,
  //     ].filter(Boolean);
  //     return superiorCourses.some((superiorCourse) =>
  //       session.carrello?.some((item) => item.articolo === superiorCourse)
  //     );
  //   };

  //   // Controlla se il corso è nel carrello
  //   const isCourseInCart = (courseTitle) => {
  //     return session.carrello?.some((item) => item.articolo === courseTitle);
  //   };

  //   const handleListAddCarrello = async (nome, prezzo) => {
  //     const addCarrello2 = {
  //       carrello: {
  //         articolo: nome,
  //         prezzo: prezzo,
  //       },
  //     };

  //     try {
  //       const response = await client.patch(
  //         `/account/updateCarrello/${session.id}`,
  //         addCarrello2.carrello,
  //         {
  //           headers: {
  //             "Content-Type": "application/json",
  //           },
  //         }
  //       );
  //       setBooleana(true);

  //       if (response.statusCode === 200) {
  //         console.log("Dati modificati con successo");
  //         // Aggiungi qui la logica per gestire la risposta positiva dal server
  //       } else {
  //         console.error("Errore durante la modifica dei dati");
  //         // Aggiungi qui la logica per gestire la risposta negativa dal server
  //       }
  //     } catch (e) {
  //       console.error("Errore nella richiesta al server", e);
  //       // Aggiungi qui la logica per gestire gli errori durante la richiesta al server
  //     }
  //   };

  //   const handleListRemoveFromCart = async (nome, prezzo) => {
  //     const addCarrello2 = {
  //       carrello: {
  //         articolo: nome,
  //         prezzo: prezzo,
  //       },
  //     };

  //     const confirmDelete = window.confirm(
  //       "Sei sicuro di voler rimuovere questo articolo dal carrello?"
  //     );

  //     try {
  //       const response = await client.patch(
  //         `/account/removefrom/${session?.id}`,
  //         addCarrello2.carrello,
  //         {
  //           headers: {
  //             "Content-Type": "application/json",
  //           },
  //         }
  //       );
  //       setBooleana(true);

  //       if (response.statusCode === 200) {
  //         console.log("Elemento rimosso dal carrello con successo");
  //         // Aggiungi qui la logica per gestire la risposta positiva dal server
  //       } else {
  //         console.error("Errore durante la rimozione dall carrello");
  //         // Aggiungi qui la logica per gestire la risposta negativa dal server
  //       }
  //     } catch (e) {
  //       console.error("Errore nella richiesta al server", e);
  //       // Aggiungi qui la logica per gestire gli errori durante la richiesta al server
  //     }
  //   };

  return (
    <div className="margin_T110">
      {/* /////////////////////////////////////////////////  SCHERMO GRANDE*/}

      <div className="d-flex flex-wrap align-items-center justify-content-between mb-2 d-none d-md-flex d-xxl-flex">
        <div className="d-flex align-items-center font_percorso margin_S65">
          <div
            className="puntatore text_percorso text_m1"
            onClick={handleRamo0Click}
          >
            Webinar
          </div>
          <span className="text_slash mb-1 ms-1">/</span>
          {/* asd */}
          <div
            className={`${
              content.webinar?.ramo2 ? "puntatore" : ""
            } text_percorso text_m1`}
            onClick={handleRamo1Click}
          >
            {content.webinar?.ramo1}
          </div>
          {content.webinar?.ramo2 && (
            <div className=" d-flex align-items-center text_percorso">
              <span className="text_slash mb-1 ms-1">/</span>
              <div
                className={content.webinar?.ramo3 ? "puntatore" : ""}
                onClick={handleRamo2Click}
              >
                {content.webinar?.ramo2}
              </div>
              {content.webinar?.ramo3 && (
                <div className="d-flex align-items-center">
                  <span className="text_slash mb-1 ms-1">/</span>
                  <div
                    className={content.webinar?.ramo4 ? "puntatore" : ""}
                    onClick={handleRamo3Click}
                  >
                    {content.webinar?.ramo3}
                  </div>
                  {content.webinar?.ramo4 && (
                    <div className="d-flex align-items-center text_percorso">
                      <span className="text_slash mb-1 ms-1">/</span>
                      {content.webinar?.ramo4}
                    </div>
                  )}
                </div>
              )}
            </div>
          )}
        </div>
      </div>

      {/* ///////////////////////////////////////////////// SCHERMO PICCOLO */}

      <div className="d-flex flex-column  align-items-center justify-content-between mb-2 d-md-none">
        <div className="d-flex align-items-center font_percorso  d-none d-sm-flex d-md-flex">
          <div
            className={`${
              content.webinar?.ramo2 ? "puntatore" : ""
            } text_percorso text_m1`}
            onClick={handleRamo1Click}
          >
            {content.webinar?.ramo1}
          </div>
          {content.webinar?.ramo2 && (
            <div className=" d-flex align-items-center text_percorso">
              <span className="text_slash mb-1 ms-1">/</span>
              <div
                className={content.webinar?.ramo3 ? "puntatore" : ""}
                onClick={handleRamo2Click}
              >
                {content.webinar?.ramo2}
              </div>
              {content.webinar?.ramo3 && (
                <div className="d-flex align-items-center">
                  <span className="text_slash mb-1 ms-1">/</span>
                  <div
                    className={content.webinar?.ramo4 ? "puntatore" : ""}
                    onClick={handleRamo3Click}
                  >
                    {content.webinar?.ramo3}
                  </div>
                  {content.webinar?.ramo4 && (
                    <div className="d-flex align-items-center text_percorso">
                      <span className="text_slash mb-1 ms-1">/</span>
                      {content.webinar?.ramo4}
                    </div>
                  )}
                </div>
              )}
            </div>
          )}
        </div>
      </div>

      <div className="text-center">
        <img
          className="imgBox-cover-home my-3"
          src={content.webinar?.cover}
          alt="cover"
        />
        <Container>
          <div className="text-start  ms-5">
            <h1 className="my-4">{content.webinar?.titolo}</h1>
            <div className="mb-5">
              <div dangerouslySetInnerHTML={{ __html: sanitizedHTML }} />
            </div>
          </div>
        </Container>

        <VideoCardList idCorso={id} link="videoWebinarId" />

        {sottoCorsi && sottoCorsi.webinar && sottoCorsi.webinar.length > 0 && (
          <>
            <hr className="mb-3" />
            <h5 className="mb-4">Webinar :</h5>
          </>
        )}

        <Container fluid>
          <Row className="d-flex flex-wrap justify-content-center">
            {sottoCorsi &&
              sottoCorsi.webinar?.map((webinar) => {
                const renderTooltip = (props) => (
                  <Tooltip id="button-tooltip" {...props}>
                    {webinar.titolo}
                  </Tooltip>
                );

                return (
                  <Col key={webinar._id} sm={12} md={6} lg={4} xl={3}>
                    <Card className="cardCustom">
                      <Link
                        to={`/webinar/${webinar._id}`}
                        className="clear_link_black"
                      >
                        <Card.Img
                          className="imgCard"
                          style={{ width: "18rem", height: "10rem" }}
                          variant="top"
                          src={webinar.cover}
                        />
                        <Card.Body
                          style={{
                            height: "88px",
                          }}
                        >
                          <Card.Title>{webinar.titolo}</Card.Title>
                        </Card.Body>
                      </Link>
                    </Card>
                  </Col>
                );
              })}
          </Row>
        </Container>
        {/* {sottoCorsi && sottoCorsi.webinar && sottoCorsi.webinar.length > 0 && (
          <h5>Sotto corsi:</h5>
        )} */}

        {/* {sottoCorsi &&
          sottoCorsi.webinar?.map((webinar) => {
            const renderTooltip = (props) => (
              <Tooltip id="button-tooltip" {...props}>
                {webinar.titolo}
              </Tooltip>
            );
            return (
              <div key={nanoid()}> */}
        {/* SCHERMO GRANDE */}
        {/* <div
                  key={nanoid()}
                  className="cta2 justify-content-between align-items-center mb-4 d-none d-md-flex d-xxl-flex"
                >
                  <div className="cta2_asd d-flex align-items-center justify-content-between">
                    <a
                      href={`/view/${webinar._id}`}
                      className="clear_link_black"
                    >
                      <img src={webinar.cover} alt="cover" />
                    </a>
                  </div>
                  <div className="cta2DivEllipsis ">
                    <a
                      href={`/corsi/${webinar._id}`}
                      className="clear_link_black"
                    >
                      <OverlayTrigger
                        placement="top"
                        delay={{ show: 300, hide: 100 }}
                        overlay={renderTooltip}
                      >
                        <p className="ctaEllisse">{webinar.titolo}</p>
                      </OverlayTrigger>
                    </a>
                  </div> */}

        {/* <div className="cta2_div">
                    <Cart2 className=" cta2_pulsanti" color="black" size={30} />

                    {isCourseInCart(webinar.titolo) ? (
                      <DashCircle
                        className="cta2_pulsanti"
                        color="black"
                        size={20}
                        role="button"
                        onClick={() =>
                          handleListRemoveFromCart(course.titolo, course.prezzo)
                        }
                      />
                    ) : isSuperiorCourseInCart(course) ? (
                      <CheckCircle color="grey" size={25} />
                    ) : purchase ? (
                      <CheckCircle color="green" size={25} />
                    ) : (
                      <PlusCircle
                        className="cta2_pulsanti"
                        color="black"
                        size={20}
                        role="button"
                        onClick={() =>
                          handleListAddCarrello(course.titolo, course.prezzo)
                        }
                      />
                    )}
                  </div> 
                </div>*/}

        {/* SCHERMO PICCOLO */}
        {/* <div
                  key={nanoid()}
                  className="cta2 justify-content-center align-items-center mb-4 d-md-none"
                >
                  <div className="cta2DivEllipsis ">
                    <a
                      href={`/corsi/${webinar._id}`}
                      className="clear_link_black"
                    >
                      <OverlayTrigger
                        placement="top"
                        delay={{ show: 300, hide: 100 }}
                        overlay={renderTooltip}
                      >
                        <p className="ctaEllisse">{webinar.titolo}</p>
                      </OverlayTrigger>
                    </a>
                  </div>
                </div>
              </div>
            );
          })} */}

        {/* {sottoCorsi &&
          isLoading === false &&
          sottoCorsi.webinar &&
          sottoCorsi.webinar.length > 0 && <hr className="mt-3 mb-3" />} */}
        <hr className="mt-3 mb-3" />
        {/* SCHERMO GRANDE */}
        <div className="my-5 justify-content-center d-none d-md-flex d-xxl-flex">
          <img
            className=" "
            style={{ borderRadius: "20px" }}
            src={BannerPubblCashback}
            alt="cover"
          />
        </div>
        {/* SCHERMO PICCOLO */}
        <div className="my-5 justify-content-center d-md-none">
          <img
            className="w-100"
            //style={{ height: "50px", width: "300px" }}
            style={{ objectFit: "contain" }}
            src={BannerPubblCashback}
            alt="cover"
          />
        </div>
      </div>
    </div>
  );
};

export default NodeViewWeb;

{
  /* <div key={nanoid()}>
                     ---------------------------------------------- SCHERMO GRANDE 
                     <Col key={webinar._id} sm={12} md={6} lg={4} xl={3}>
                  <Card className="cardCustom">
                    <Card.Img
                      className="imgCard"
                      style={{ width: "18rem", height: "10rem" }}
                      variant="top"
                      src={webinar.cover}
                    />
                    <Card.Body>
                      <Card.Title>{webinar.titolo}</Card.Title>

                      <Link
                        to={`/webinar/${webinar._id}`}
                        className="clear_link_black"
                      >
                        <Button variant="outline-danger">Vai al webinar</Button>
                      </Link>
                    </Card.Body>
                  </Card>
                </Col> */
}
{
  /* <div
                  key={nanoid()}
                  className="cta2  align-items-center mb-4 d-none d-md-flex d-xxl-flex"
                >
                  <div className="cta2_asd d-flex align-items-center ">
                    <a
                      href={`/dettagliWebinar/${webinar._id}`}
                      className="clear_link_black"
                    >
                      <img src={webinar.cover} alt="cover" />
                    </a>
                  </div>
                  <div className="cta2DivEllipsis ">
                    <a
                      href={`/webinar/${webinar._id}`}
                      className="clear_link_black"
                    >
                      <OverlayTrigger
                        placement="top"
                        delay={{ show: 300, hide: 100 }}
                        overlay={renderTooltip}
                      >
                        <p className="ctaEllisse">{webinar.titolo}</p>
                      </OverlayTrigger>
                    </a>
                  </div>
                </div> */
}

{
  /* ---------------------------------------------- SCHERMO PICCOLO */
}
{
  /* <div
                      key={nanoid()}
                      className="cta2_mobile justify-content-center align-items-center justify-content-between mb-4 d-md-none"
                    >
                      <div className="cta2_asd d-flex align-items-center ">
                        <a
                          href={`/dettagliWebinar/${webinar._id}`}
                          className="clear_link_black"
                        >
                          <img src={webinar.cover} alt="cover" />
                        </a>
                      </div>
                      <div className="cta2DivEllipsis ">
                        <a
                          href={`/dettagliWebinar/${webinar._id}`}
                          className="clear_link_black"
                        >
                          <OverlayTrigger
                            placement="top"
                            delay={{ show: 300, hide: 100 }}
                            overlay={renderTooltip}
                          >
                            <p className="ctaEllisse">{webinar.titolo}</p>
                          </OverlayTrigger>
                        </a>
                      </div>
                      <div className="cta2_div"></div>
                    </div> 
                   </div>*/
}
