import React, { useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
import AxiosClient from "../../client/client";
import { nanoid } from "nanoid";
import { ChevronDown, ChevronRight } from "react-bootstrap-icons";

const SidebarWeb = () => {
  const client = new AxiosClient();
  const [allCourse, setAllCourse] = useState([]);
  const { id } = useParams();

  const [ramo1, setRamo1] = useState([]);
  const [ramo2, setRamo2] = useState([]);
  const [ramo3, setRamo3] = useState([]);
  const [ramo4, setRamo4] = useState([]);

  const getCourse = async () => {
    try {
      const response = await client.get("/webinars");
      setAllCourse(response);
    } catch (e) {
      console.log(e);
    }
  };

  //get ramo1
  const getRamo1 = async () => {
    try {
      const response = await client.get(`/webinars/ramo1`);
      setRamo1(response);
    } catch (e) {
      console.log(e);
    }
  };

  //get ramo2
  const getRamo2 = async (parametro) => {
    try {
      const response = await client.get(`/webinars/ramo2/${parametro}`);
      setRamo2((prevRamo2) => ({
        ...prevRamo2,
        [parametro]: response.webinar || [], // Assicurati che response.course sia un array
      }));
    } catch (e) {
      console.log(e);
    }
  };

  // get ramo3
  const getRamo3 = async (parametro) => {
    try {
      const response = await client.get(`/webinars/ramo3/${parametro}`);

      setRamo3((prevRamo3) => ({
        ...prevRamo3,
        [parametro]: response.webinar || [], // Assicurati che response.course sia un array
      }));
    } catch (e) {
      console.log(e);
    }
  };

  // get ramo4
  const getRamo4 = async (parametro) => {
    try {
      const response = await client.get(`/webinars/ramo4/${parametro}`);
      setRamo4((prevRamo4) => ({
        ...prevRamo4,
        [parametro]: response.webinar || [], // Assicurati che response.course sia un array
      }));
    } catch (e) {
      console.log(e);
    }
  };

  useEffect(() => {
    getCourse();
    getRamo1();
  }, []);

  //useEffect per ramo2
  useEffect(() => {
    if (ramo1.webinar && ramo1.webinar.length > 0) {
      ramo1.webinar.forEach((webinar) => {
        getRamo2(webinar.titolo);
      });
    }
  }, [ramo1]);

  //useEffect per ramo3
  useEffect(() => {
    // Itera sulle chiavi di ramo2
    Object.keys(ramo2).forEach((webinarTitolo) => {
      // Accedi all'array di corsi associato a ogni titolo
      const webinars = ramo2[webinarTitolo];

      // Itera sui corsi e chiama la funzione getRamo3
      webinars.forEach((webinar) => {
        getRamo3(webinar.titolo);
      });
    });
  }, [ramo2]);

  //useEffect per ramo4
  useEffect(() => {
    Object.keys(ramo3).forEach((webinarTitolo) => {
      const webinars = ramo3[webinarTitolo];
      webinars.forEach((webinar) => {
        getRamo4(webinar.titolo);
      });
    });
  }, [ramo3]);

  const [toggleStates, setToggleStates] = useState({});
  const [toggleStates2, setToggleStates2] = useState({});
  const [toggleStates3, setToggleStates3] = useState({});

  const handleToggle = (index) => {
    setToggleStates((prevStates) => ({
      ...prevStates,
      [index]: !prevStates[index],
    }));
  };

  const handleToggle2 = (index, index2) => {
    setToggleStates2((prevStates2) => ({
      ...prevStates2,
      [index]: {
        ...prevStates2[index],
        [index2]: !prevStates2[index]?.[index2],
      },
    }));
  };

  const handleToggle3 = (index, index2, index3) => {
    setToggleStates3((prevStates3) => ({
      ...prevStates3,
      [index]: {
        ...prevStates3[index],
        [index2]: {
          ...prevStates3[index]?.[index2],
          [index3]: !prevStates3[index]?.[index2]?.[index3],
        },
      },
    }));
  };

  return (
    <>
      <div className="contenutoSidebar">
        {ramo1.webinar &&
          ramo1.webinar.map((webinar, index) => {
            const isSelected = id === webinar._id;
            return (
              <div key={nanoid()} className="prove5 mb-3">
                <div className="d-flex asd1" key={nanoid()}>
                  {!ramo2[webinar.titolo]?.length && (
                    <div
                      key={nanoid()}
                      className="me-1 mb-4"
                      style={{ marginLeft: "21px" }}
                    ></div>
                  )}
                  {ramo2[webinar.titolo]?.length > 0 && (
                    <div
                      key={nanoid()}
                      className="pt-1 me-1"
                      onClick={() => handleToggle(index)}
                    >
                      {toggleStates[index] ? (
                        <ChevronDown color="white" size={20} role="button" />
                      ) : (
                        <ChevronRight color="white" size={20} role="button" />
                      )}
                    </div>
                  )}
                  <p className="text_ramo1" key={nanoid()}>
                    <Link
                      className={`clear_link`}
                      to={`/webinar/${webinar._id}`}
                    >
                      {webinar.titolo}
                      {isSelected && <span> ●</span>}
                    </Link>
                  </p>
                </div>
                <div className="prove6" key={nanoid()}>
                  {ramo2[webinar.titolo] &&
                    ramo2[webinar.titolo].map((webinar2, index2) => {
                      const isSelected = id === webinar2._id;
                      return (
                        <div
                          key={nanoid()}
                          className={
                            toggleStates[index] ? "toggleSI" : "toggleNO"
                          }
                        >
                          <div className="d-flex me-2 asd2">
                            {!ramo3[webinar2.titolo]?.length && (
                              <div
                                className="me-1 mb-4"
                                style={{ marginLeft: "28px" }}
                              ></div>
                            )}
                            {ramo3[webinar2.titolo]?.length > 0 && (
                              <div
                                className="ms-2 me-1 pt-1"
                                onClick={() => handleToggle2(index, index2)}
                              >
                                {toggleStates2[index]?.[index2] ? (
                                  <ChevronDown
                                    color="white"
                                    size={20}
                                    role="button"
                                  />
                                ) : (
                                  <ChevronRight
                                    color="white"
                                    size={20}
                                    role="button"
                                  />
                                )}
                              </div>
                            )}
                            <p className="py-1 text-light text_ramo2">
                              <Link
                                className="clear_link"
                                to={`/webinar/${webinar2._id}`}
                              >
                                {webinar2.titolo}
                                {isSelected && <span> ●</span>}
                              </Link>
                            </p>
                          </div>

                          <div className="prove7">
                            {ramo3[webinar2.titolo] &&
                              ramo3[webinar2.titolo].map((webinar3, index3) => {
                                const isSelected = id === webinar3._id;
                                return (
                                  <div
                                    key={nanoid()}
                                    className={
                                      toggleStates2[index]?.[index2]
                                        ? "toggleSI"
                                        : "toggleNO"
                                    }
                                  >
                                    <div className="d-flex align-items-center asd3">
                                      {!ramo4[webinar3.titolo]?.length && (
                                        <div
                                          className="me-1 mb-4"
                                          style={{ marginLeft: "15px" }}
                                        ></div>
                                      )}
                                      {ramo4[webinar3.titolo]?.length > 0 && (
                                        <div
                                          className="me-1 mb-4 "
                                          onClick={() =>
                                            handleToggle3(index, index2, index3)
                                          }
                                        >
                                          {toggleStates3[index]?.[index2]?.[
                                            index3
                                          ] ? (
                                            <ChevronDown
                                              color="white"
                                              size={14}
                                              role="button"
                                            />
                                          ) : (
                                            <ChevronRight
                                              color="white"
                                              size={14}
                                              role="button"
                                            />
                                          )}
                                        </div>
                                      )}
                                      <p className="py-1 text_ramo3">
                                        <Link
                                          className="clear_link"
                                          to={`/webinar/${webinar3._id}`}
                                        >
                                          {webinar3.titolo}
                                          {isSelected && <span> ●</span>}
                                        </Link>
                                      </p>
                                    </div>

                                    {ramo4[webinar3.titolo] &&
                                      ramo4[webinar3.titolo].map((webinar4) => {
                                        const isSelected = id === webinar4._id;
                                        return (
                                          <div
                                            className="prove8"
                                            key={nanoid()}
                                          >
                                            <div
                                              className={
                                                toggleStates3[index]?.[
                                                  index2
                                                ]?.[index3]
                                                  ? "toggleSI"
                                                  : "toggleNO"
                                              }
                                            >
                                              <div
                                                className="asd4"
                                                style={{ marginLeft: "15px" }}
                                              >
                                                <p className="ramo4Mod text_ramo4">
                                                  <Link
                                                    className="clear_link"
                                                    to={`/webinar/${webinar4._id}`}
                                                  >
                                                    {webinar4.titolo}
                                                    {isSelected && (
                                                      <span> ●</span>
                                                    )}
                                                  </Link>
                                                </p>
                                              </div>
                                            </div>
                                          </div>
                                        );
                                      })}
                                  </div>
                                );
                              })}
                          </div>
                        </div>
                      );
                    })}
                </div>
              </div>
            );
          })}
      </div>
    </>
  );
};

export default SidebarWeb;
