// import React, { useEffect, useState } from "react";
// import AxiosClient from "../client/client";
// import { Link } from "react-router-dom";
// import { CaretDownFill, CaretRightFill, Trash3 } from "react-bootstrap-icons";
// import AlertMessage from "../components/alertMessage/AlertMessage";
// import { PuffLoader } from "react-spinners";
// import ResponsivePagination from "react-responsive-pagination";
// import "react-responsive-pagination/themes/classic.css";

// const GestionaleCorsi = () => {
//   const [successMessage, setSuccessMessage] = useState(null);
//   const [failedMessage, setFailedMessage] = useState(null);
//   const client = new AxiosClient();
//   const [accounts, setAccounts] = useState([]);

//   const [isLoadingAccounts, setIsLoadingAccounts] = useState(false);
//   const [isLoadingDelete, setIsLoadingDelete] = useState(false);

//   const [currentPage, setCurrentPage] = useState(1);

//   const [searchTerm, setSearchTerm] = useState("");

//   // GET ACCOUNT
//   const getAccounts = async () => {
//     try {
//       setIsLoadingAccounts(true);
//       const response = await client.get(
//         `/courses/byTitle?title=${searchTerm}&page=${currentPage}`
//       );
//       setAccounts(response);
//       setIsLoadingAccounts(false);
//     } catch (error) {
//       console.log(error);
//     }
//   };

//   const handlePagination = (value) => {
//     setCurrentPage(value);
//   };

//   const handleSearchTermChange = (newTerm) => {
//     setSearchTerm(newTerm);
//     setCurrentPage(1); // Imposta currentPage a 1 quando il termine di ricerca cambia
//   };

//   useEffect(() => {
//     getAccounts();
//   }, [currentPage, searchTerm]);

//   console.log(accounts.accounts);

//   const deleteAccount = async (idAccount) => {
//     const confirmDelete = window.confirm(
//       "Sei sicuro di voler eliminare questo account?"
//     );

//     if (confirmDelete) {
//       setIsLoadingDelete(true);

//       setTimeout(async () => {
//         try {
//           const response = await client.delete(`course/delete/${idAccount}`);

//           if (response.statusCode === 200) {
//             console.log("Eliminazione account avvenuta con successo");
//             setIsLoadingDelete(false);
//             setSuccessMessage("Account eliminato con successo!");
//             getAccounts();
//             setTimeout(() => {
//               setSuccessMessage(null);
//             }, 3000);
//           } else {
//             console.error(
//               "Errore durante l'eliminazione dell'account",
//               response
//             );
//             setIsLoadingDelete(false);
//             setFailedMessage("Errore durante l'eliminazione dell'account!");
//             setTimeout(() => {
//               setFailedMessage(null);
//             }, 3000);
//           }
//         } catch (error) {
//           console.log(error);
//           setIsLoadingDelete(false);
//           setFailedMessage("Errore nella richiesta al server");
//           setTimeout(() => {
//             setFailedMessage(null);
//           }, 3000);
//         }
//       }, 2000);
//     }
//   };

//   const handleDeleteClick = (accountId) => {
//     return () => {
//       deleteAccount(accountId);
//     };
//   };

//   return (
//     <>
//       {successMessage && (
//         <div>
//           <AlertMessage message={successMessage} success={true} />
//         </div>
//       )}

//       {failedMessage && (
//         <div>
//           <AlertMessage message={failedMessage} success={false} />
//         </div>
//       )}

//       {isLoadingDelete && (
//         <div className="alert-container">
//           <PuffLoader color="#ec800f" />
//         </div>
//       )}

//       <div className="margin_T150">
//         {/* LISTA ACCOUNT */}
//         <div className="container mb-5 gestionale-bg">
//           <div className="d-flex align-items-center justify-content-between">
//             <h2 className="me-3 my-0">CORSI</h2>

//             <input
//               className="my_input"
//               type="text"
//               placeholder="Cerca per titolo"
//               value={searchTerm}
//               onChange={(e) => handleSearchTermChange(e.target.value)}
//             />
//           </div>

//           {accounts &&
//             accounts.courses
//               ?.filter((account) =>
//                 account.titolo.toLowerCase().includes(searchTerm.toLowerCase())
//               )
//               .map((account) => {
//                 return (
//                   <div
//                     key={account._id}
//                     className="d-flex justify-content-between align-items-center my-4 p-account-box-singlePost"
//                   >
//                     <Link
//                       to={`/gestionaleAccount/${account._id}`}
//                       className="clear_link_black"
//                     >
//                       <div className="d-flex ms-3">
//                         <h5>{account.titolo}</h5>
//                       </div>
//                     </Link>

//                     <Trash3
//                       onClick={handleDeleteClick(account._id)}
//                       color="red"
//                       size={25}
//                       role="button"
//                       className="mx-3"
//                     />
//                   </div>
//                 );
//               })}
//           <ResponsivePagination
//             current={currentPage}
//             total={accounts && accounts.totalPages}
//             onPageChange={handlePagination}
//           />
//         </div>
//         <div className="mb-5"></div>
//       </div>
//     </>
//   );
// };

// export default GestionaleCorsi;

import React, { useEffect, useState } from "react";
import AxiosClient from "../client/client";
import { Link } from "react-router-dom";
import { CaretDownFill, CaretRightFill, Trash3 } from "react-bootstrap-icons";
import AlertMessage from "../components/alertMessage/AlertMessage";
import { PuffLoader } from "react-spinners";
import ResponsivePagination from "react-responsive-pagination";
import "react-responsive-pagination/themes/classic.css";

const GestionaleCorsi = () => {
  const [successMessage, setSuccessMessage] = useState(null);
  const [failedMessage, setFailedMessage] = useState(null);
  const client = new AxiosClient();
  const [accounts, setAccounts] = useState([]);
  const [courses, setCourses] = useState([]);

  const [isLoadingAccounts, setIsLoadingAccounts] = useState(false);
  const [isLoadingDelete, setIsLoadingDelete] = useState(false);

  const [currentPage, setCurrentPage] = useState(1);

  const [searchTerm, setSearchTerm] = useState("");

  // GET ACCOUNT
  const getCourses = async () => {
    try {
      setIsLoadingAccounts(true);
      const response = await client.get(
        `/courses/byTitle?title=${searchTerm}&page=${currentPage}`
      );
      setCourses(response);
      setIsLoadingAccounts(false);
    } catch (error) {
      console.log(error);
    }
  };

  const handlePagination = (value) => {
    setCurrentPage(value);
  };

  const handleSearchTermChange = (newTerm) => {
    setSearchTerm(newTerm);
    setCurrentPage(1); // Imposta currentPage a 1 quando il termine di ricerca cambia
  };

  useEffect(() => {
    getCourses();
  }, [currentPage, searchTerm]);

  //console.log(accounts.accounts);

  const deleteCourse = async (idCourse) => {
    const confirmDelete = window.confirm(
      "Sei sicuro di voler eliminare questo corso?"
    );

    if (confirmDelete) {
      setIsLoadingDelete(true);

      setTimeout(async () => {
        try {
          // const response = await client.delete(`course/delete/${idCourse}`);
          // const response = await client.delete(
          //   `courseDelete/cloudUpload/${idCourse}`
          const response = await client.delete(`deleteAllCourse/${idCourse}`);
          if (response.statusCode === 200) {
            console.log("Eliminazione corso avvenuto con successo");
            setIsLoadingDelete(false);
            setSuccessMessage("Corso eliminato con successo!");
            getCourses();
            setTimeout(() => {
              setSuccessMessage(null);
            }, 3000);
          } else {
            console.error("Errore durante l'eliminazione del corso", response);
            setIsLoadingDelete(false);
            setFailedMessage("Errore durante l'eliminazione del corso!");
            setTimeout(() => {
              setFailedMessage(null);
            }, 3000);
          }
        } catch (error) {
          console.log(error);
          setIsLoadingDelete(false);
          setFailedMessage("Errore nella richiesta al server");
          setTimeout(() => {
            setFailedMessage(null);
          }, 3000);
        }
      }, 2000);
    }
  };

  const handleDeleteClick = (corsoId) => {
    return () => {
      deleteCourse(corsoId);
    };
  };

  return (
    <>
      {successMessage && (
        <div>
          <AlertMessage message={successMessage} success={true} />
        </div>
      )}

      {failedMessage && (
        <div>
          <AlertMessage message={failedMessage} success={false} />
        </div>
      )}

      {isLoadingDelete && (
        <div className="alert-container">
          <PuffLoader color="#ec800f" />
        </div>
      )}

      <div className="margin_T150">
        {/* LISTA ACCOUNT */}
        <div className="container mb-5 gestionale-bg">
          <div className="d-flex align-items-center justify-content-between">
            <h2 className="me-3 my-0">CORSI</h2>

            <input
              className="my_input"
              type="text"
              placeholder="Cerca per titolo"
              value={searchTerm}
              onChange={(e) => handleSearchTermChange(e.target.value)}
            />
          </div>

          {courses &&
            courses.courses
              ?.filter((corso) =>
                corso.titolo.toLowerCase().includes(searchTerm.toLowerCase())
              )
              .map((corso) => {
                return (
                  <div
                    key={corso._id}
                    className="d-flex justify-content-between align-items-center my-4 p-account-box-singlePost"
                  >
                    <Link
                      to={`/gestionaleCorsoID/${corso._id}`}
                      className="clear_link_black"
                    >
                      <div className="d-flex ms-3">
                        <h5>{corso.titolo}</h5>
                      </div>
                    </Link>

                    <Trash3
                      onClick={handleDeleteClick(corso._id)}
                      color="red"
                      size={25}
                      role="button"
                      className="mx-3"
                    />
                  </div>
                );
              })}
          <ResponsivePagination
            current={currentPage}
            total={courses && courses.totalPages}
            onPageChange={handlePagination}
          />
        </div>
        <div className="mb-5"></div>
      </div>
    </>
  );
};

export default GestionaleCorsi;
