import React, { useEffect, useState } from "react";
import VideoCardItem from "../videoCardItem/VideoCardItem";
import AxiosClient from "../../../client/client";
import { nanoid } from "nanoid";

const VideoCardList = ({ idCorso, link }) => {
  const client = new AxiosClient();
  const [video, setVideo] = useState([]);

  const getVideo = async () => {
    const response = await client.get(`/corso/viewVideos/${idCorso}`);
    setVideo(response);
  };

  useEffect(() => {
    getVideo();
  }, [idCorso]);

  return (
    // <>
    //   {video.videos && video.videos.length > 0 && <></>}
    //   <div className="container d-flex flex-column justify-content-center align-items-center spinner-margin">
    //     {video &&
    //       video.videos?.map((videoCard) => {
    //         return (
    //           <VideoCardItem
    //             className="d-block"
    //             key={nanoid()}
    //             _id={videoCard._id}
    //             titolo={videoCard.titolo}
    //             cover={videoCard.cover}
    //           />
    //         );
    //       })}
    //   </div>
    // </>
    <>
      {video.videos && video.videos.length > 0 && (
        <>
          <hr className="mb-3" />
          <div className="container d-flex flex-column justify-content-center align-items-center spinner-margin mb-5">
            {video &&
              video.videos?.map((videoCard) => {
                return (
                  <VideoCardItem
                    className="d-block"
                    key={nanoid()}
                    link={link}
                    _id={videoCard._id}
                    titolo={videoCard.titolo}
                    descrizioneBreve={videoCard.descrizioneBreve}
                    cover={videoCard.cover}
                  />
                );
              })}
          </div>
          {/* <hr className="mt-3 mb-3" /> */}
        </>
      )}
    </>
  );
};

export default VideoCardList;
