import React, { useEffect, useState } from "react";
import AxiosClient from "../client/client";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import InputGroup from "react-bootstrap/InputGroup";
import { version } from "dompurify";
import AlertMessage from "../components/alertMessage/AlertMessage";

const VersionSoftware = () => {
  const client = new AxiosClient();
  const [successMessage, setSuccessMessage] = useState(null);
  const [failedMessage, setFailedMessage] = useState(null);
  const [softwares, setSoftwares] = useState([]);
  const [ricarica, setRicarica] = useState(false);
  const [versione, setVersione] = useState({
    versione: "",
  });

  const Recharge = () => {
    if (ricarica === false) {
      setRicarica(true);
    } else {
      setRicarica(false);
    }
  };

  // GET ACCOUNT
  const getSoftwares = async () => {
    try {
      const response = await client.get(`/versionSoftwares`);
      setSoftwares(response);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getSoftwares();
  }, [ricarica]);

  const handleModVersion = (e) => {
    const { name, value } = e.target;
    setVersione({
      ...versione,
      [name]: value,
    });
  };

  const handleEditVersion = async (id, e) => {
    const confirmDelete = window.confirm(
      "Sei sicuro di voler modificare la versione del software?"
    );

    if (confirmDelete) {
      try {
        const response = await client.patch(
          `/versionSoftware/update/${id}`,
          versione,
          {
            headers: {
              "Content-Type": "application/json",
            },
          }
        );

        if (response.statusCode === 200) {
          console.log("Versione modificata con successo");
          setSuccessMessage("Versione modificata con successo!");
          Recharge();
          setTimeout(() => {
            setSuccessMessage(null);
          }, 2000);
        } else {
          console.error("Errore durante la modifica della versione");
          setFailedMessage("Errore durante la modifica della versione!");
          setTimeout(() => {
            setFailedMessage(null);
          }, 2000);
        }
      } catch (e) {
        console.error("Errore nella richiesta al server", e);
        setFailedMessage("Errore nella richiesta al server");
        setTimeout(() => {
          setFailedMessage(null);
        }, 2000);
      }
    }
  };

  return (
    <>
      {successMessage && (
        <div>
          <AlertMessage message={successMessage} success={true} />
        </div>
      )}

      {failedMessage && (
        <div>
          <AlertMessage message={failedMessage} success={false} />
        </div>
      )}

      <div className="text-center container component-bg margin_T180">
        {softwares &&
          softwares.versionSoftwares?.map((software) => {
            return (
              <div
                key={software._id}
                className="my-3 d-flex justify-content-center align-items-center"
              >
                <span>
                  <strong>{software.nome}</strong> -
                  <strong> {software.versione}</strong>
                </span>
                <InputGroup
                  className="ms-4 custom-focus"
                  style={{ width: "200px" }}
                >
                  <Form.Control
                    name="versione"
                    placeholder="Nuova versione"
                    aria-label="Recipient's username"
                    aria-describedby="basic-addon2"
                    onChange={handleModVersion}
                  />
                  <Button
                    onClick={() => {
                      handleEditVersion(software._id);
                    }}
                    id="button-addon2"
                  >
                    Invia
                  </Button>
                </InputGroup>
              </div>
            );
          })}
      </div>
    </>
  );
};

export default VersionSoftware;
