import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import AxiosClient from "../client/client";
import { Button, Container, Form, Modal } from "react-bootstrap";
import AlertMessage from "../components/alertMessage/AlertMessage";
import { PuffLoader } from "react-spinners";
import ReactQuill from "react-quill";
import DOMPurify from "dompurify";
import "./_textEditor.scss";
import "react-quill/dist/quill.snow.css";
import ReactPlayer from "react-player";

const GestionaleVideoID = () => {
  const { id } = useParams();
  const client = new AxiosClient();
  const [video, setVideo] = useState([]);
  const [corso, setCorso] = useState([]);
  const [successMessage, setSuccessMessage] = useState(null);
  const [failedMessage, setFailedMessage] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [selectedImage, setSelectedImage] = useState(null);
  const [imagePreview, setImagePreview] = useState(null);
  const [s3Image, setS3Image] = useState(null);
  const [s3ImageChange, setS3ImageChange] = useState(null);
  const [showModal, setShowModal] = useState(false);
  const [formData, setFormData] = useState({
    titolo: "",
    descrizione: "",
    descrizioneBreve: "",
    video: "",
    corso: "",
  });

  //cambio img

  const GetVideo = async () => {
    try {
      const response = await client.get(`/videoByID/${id}`);
      setVideo(response);
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    GetVideo();
  }, []);
  const sanitizedHTML = DOMPurify.sanitize(video.video?.descrizione);

  const openEditModal = (accountData) => {
    setFormData({
      titolo: video.video?.titolo,
      descrizione: video.video?.descrizione,
      descrizioneBreve: video.video?.descrizioneBreve,
      video: video.video?.video,
      corso: video.video?.corso?._id,
    });
    setShowModal(true);
  };

  const handleModAccount = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };
  const handleQuillChange = (value) => {
    setFormData({
      ...formData,
      descrizione: value,
    });
  };

  const handleEditAccount = async (e) => {
    e.preventDefault();
    try {
      const response = await client.patch(`/video/update/${id}`, formData, {
        headers: {
          "Content-Type": "application/json",
        },
      });

      if (response.statusCode === 200) {
        console.log("Dati modificati con successo");
        setShowModal(false);
        setSuccessMessage("Video modificato con successo!");
        GetVideo();
        setTimeout(() => {
          setSuccessMessage(null);
        }, 3000);
      } else {
        console.error("Errore durante la modifica dei dati");
        setFailedMessage("Errore durante la modifica del video!");
        setTimeout(() => {
          setFailedMessage(null);
        }, 3000);
      }
    } catch (e) {
      console.error("Errore nella richiesta al server", e);
      setFailedMessage("Errore nella richiesta al server");
      setTimeout(() => {
        setFailedMessage(null);
      }, 3000);
    }
  };

  const getCourse = async () => {
    try {
      const response = await client.get("/courses");
      setCorso(response);
    } catch (e) {
      console.log(e);
    }
  };

  useEffect(() => {
    getCourse();
  }, []);

  ////////////

  const changeCover = async (cover) => {
    const fileData = new FormData();
    fileData.append("cover", cover);
    try {
      const response = await fetch(
        `${process.env.REACT_APP_SERVER_BASE_URL}/videos/cloudUpload/${id}`,
        {
          method: "PATCH",
          body: fileData,
        }
      );
      return await response.json();
    } catch (e) {
      console.log(e, "Errore in uploadFile");
      // setFailedMessage("Errore upload del file!");
      //setTimeout(() => {
      //  setFailedMessage(null);
      // }, 3000);
    }
  };

  const onChangeSetFile = (e) => {
    // setSelectedImage(e.target.files[0]);
    const file = e.target.files[0];

    if (file) {
      setSelectedImage(file);
      setImagePreview(URL.createObjectURL(file));
    } else {
      setSelectedImage(null);
      setImagePreview(null);
    }
  };

  const onMainButtonClick = async () => {
    setIsLoading(true);
    if (selectedImage) {
      const fileData = new FormData();
      fileData.append("cover", selectedImage);

      try {
        const response = await fetch(
          `${process.env.REACT_APP_SERVER_BASE_URL}/videosCover/cloudUpload/${id}`,
          {
            method: "PATCH",
            body: fileData,
          }
        );
        //etnzl5fomimiimlmgtiw.png
        if (response.ok) {
          const data = await response.json();
          console.log("Immagine caricata con successo:", data);

          // Ora puoi aggiornare l'account con l'avatar appena caricato
          await client.patch(
            `/video/update/${id}`,
            { cover: data.cover },
            {
              headers: {
                "Content-Type": "application/json",
              },
            }
          );
          setIsLoading(false);
          setSelectedImage(null);
          setSuccessMessage("Immagine modificata con successo!");
          GetVideo();
          setTimeout(() => {
            setSuccessMessage(null);
          }, 3000);
        } else {
          console.error("Errore durante il caricamento dell'immagine");
          setIsLoading(false);
          setFailedMessage("Errore durante il caricamento dell'immagine!");
          setTimeout(() => {
            setFailedMessage(null);
          }, 3000);
        }
      } catch (e) {
        console.error("Errore nella richiesta al server", e);
        setIsLoading(false);
        setFailedMessage("Errore nella richiesta al server");
        setTimeout(() => {
          setFailedMessage(null);
        }, 3000);
      }
    } else {
      console.log("Nessun file selezionato");
    }
  };

  let imgstonzo = selectedImage ? imagePreview : video.video?.cover;

  console.log(video);
  ///////////////////////////////////////////////////////////////////////

  const handleDeleteimgS3 = async (e) => {
    try {
      fetch(
        `${process.env.REACT_APP_SERVER_BASE_URL}/video/deleteCoverVideoS3`,
        {
          method: "DELETE",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            urlFileKey: `${video.video?.cover}`,
          }),
        }
      );
    } catch (error) {
      console.log(error);
    }
  };

  // fetch("/delete-cover", {
  //   method: "DELETE",
  //   headers: {
  //     "Content-Type": "application/json",
  //   },
  //   body: JSON.stringify({ fileKey: "1670261034234-fileName.jpg" }),
  // });
  /////////////////////////////////////////////////////////////////////////////

  const onChangeSetFileS3 = (e) => {
    const fileS3 = e.target.files[0];
    if (fileS3) {
      setS3Image(fileS3); // Solo per upload su S3
    } else {
      setS3Image(null);
    }
  };

  // FE UPLOAD IMG
  const uploadFileNewImg = async (cover) => {
    const fileData = new FormData();
    fileData.append("cover", cover);
    try {
      const response = await fetch(
        `${process.env.REACT_APP_SERVER_BASE_URL}/video/uploadCoverVideoS3`,
        {
          method: "POST",
          body: fileData,
        }
      );
      return await response.json();
    } catch (e) {
      console.log(e, "Errore in uploadFile");
      setFailedMessage("Errore upload del file!");
      setTimeout(() => {
        setFailedMessage(null);
      }, 3000);
    }
  };

  const salvaNuovaImmagine = async (e) => {
    e.preventDefault();

    setIsLoading(true);

    if (s3Image) {
      const uploadCover = await uploadFileNewImg(s3Image);

      const data = {
        cover: uploadCover.cover,
      };

      try {
        const response = await fetch(
          `${process.env.REACT_APP_SERVER_BASE_URL}/video/update/${video.video?._id}`,
          {
            method: "PATCH",
            headers: {
              "Content-Type": "application/json",
            },
            body: JSON.stringify(data),
          }
        );

        const responseData = await response.json();

        if (response.status === 200) {
          console.log("Immagine cambiata con successo: ", responseData.payload);

          setIsLoading(false);
          setSuccessMessage("Immagine cambiata con successo!");
          setTimeout(() => {
            setSuccessMessage(null);
          }, 3000);
        } else {
          setIsLoading(false);
          console.error("Errore durante la sostituzione dell'immagine");
          setFailedMessage("Errore durante la sostituzione dell'immagine!");
          setTimeout(() => {
            setFailedMessage(null);
          }, 3000);
        }
      } catch (e) {
        console.error("Errore nella richiesta al server:", e);
        setIsLoading(false);
        setFailedMessage("Errore nella richiesta al server");
        setTimeout(() => {
          setFailedMessage(null);
        }, 3000);
      }
    }
  };

  const onChangeSetFileS3cambia = (e) => {
    const fileS3Change = e.target.files[0];
    if (fileS3Change) {
      setS3ImageChange(fileS3Change); // Solo per upload su S3
    } else {
      setS3ImageChange(null);
    }
  };

  console.log("SELECT : ", selectedImage);
  console.log("ADD : ", s3Image);
  console.log("CHANGE : ", s3ImageChange);

  const cambiaNuovaImmagine = async (e) => {
    e.preventDefault();

    setIsLoading(true);

    if (s3ImageChange) {
      handleDeleteimgS3();
      const uploadCover = await uploadFileNewImg(s3ImageChange);

      const data = {
        cover: uploadCover.cover,
      };

      try {
        const response = await fetch(
          `${process.env.REACT_APP_SERVER_BASE_URL}/video/update/${video.video?._id}`,
          {
            method: "PATCH",
            headers: {
              "Content-Type": "application/json",
            },
            body: JSON.stringify(data),
          }
        );

        const responseData = await response.json();

        if (response.status === 200) {
          console.log("Immagine cambiata con successo: ", responseData.payload);

          setIsLoading(false);
          setSuccessMessage("Immagine cambiata con successo!");
          setTimeout(() => {
            setSuccessMessage(null);
          }, 3000);
        } else {
          setIsLoading(false);
          console.error("Errore durante la sostituzione dell'immagine");
          setFailedMessage("Errore durante la sostituzione dell'immagine!");
          setTimeout(() => {
            setFailedMessage(null);
          }, 3000);
        }
      } catch (e) {
        console.error("Errore nella richiesta al server:", e);
        setIsLoading(false);
        setFailedMessage("Errore nella richiesta al server");
        setTimeout(() => {
          setFailedMessage(null);
        }, 3000);
      }
    }
  };

  return (
    <>
      {successMessage && (
        <div>
          <AlertMessage message={successMessage} success={true} />
        </div>
      )}

      {failedMessage && (
        <div>
          <AlertMessage message={failedMessage} success={false} />
        </div>
      )}

      {isLoading && (
        <div className="alert-container">
          <PuffLoader color="#ec800f" size={200} />
        </div>
      )}

      <div className="margin_T150 text-center">
        <h2 className="mb-4">{video.video?.titolo}</h2>
        <div className="d-flex justify-content-center mb-4">
          <ReactPlayer
            url={video.video?.video}
            controls
            width="400px"
            height="auto"
          />
        </div>

        {/* <div className="mb-4">
          <div dangerouslySetInnerHTML={{ __html: sanitizedHTML }} />
        </div> */}
        <h6>Descrizione:</h6>
        <Container className="box-descrizione my-3">
          <div className="text-start p-3">
            <div dangerouslySetInnerHTML={{ __html: sanitizedHTML }} />
          </div>
        </Container>

        <h6>Descrizione Breve:</h6>
        <p>{video.video?.descrizioneBreve}</p>
        <h6>Corso: {video.video?.corso?.titolo}</h6>

        <div className="my-4">
          <Button onClick={() => openEditModal(video)}>Modifica</Button>
        </div>
        <div className="d-flex flex-column align-items-center justify-content-center">
          <img className="mb-4" src={imgstonzo} alt="cover" width={400} />

          <label htmlFor="coverInput" className="btn btn-primary mb-2">
            Cambia immagine
          </label>
          <input
            id="coverInput"
            type="file"
            accept="image/*"
            style={{ display: "none" }}
            onChange={onChangeSetFile}
          />
          {selectedImage && (
            <>
              <button
                type="button"
                className="btn btn-success mb-4"
                onClick={onMainButtonClick}
              >
                Salva Modifiche
              </button>
            </>
          )}
        </div>
        <div className="mb-5 ">
          <hr />
          {/* ELIMINA IMMAGINE S3 */}
          <div>
            <Button onClick={handleDeleteimgS3}>Elimina immagine S3</Button>
          </div>
          {/* AGGIUNGI IMMAGINE S3 */}
          <div className="mt-3">
            <label htmlFor="coverInputS3" className="btn btn-primary  me-3 ">
              Scegli IMG S3
            </label>
            <input
              id="coverInputS3"
              type="file"
              accept="image/*"
              style={{ display: "none" }}
              onChange={onChangeSetFileS3}
            />
            <Button onClick={salvaNuovaImmagine}>Aggiungi immagine S3</Button>
          </div>
          {/* CAMBIA IMMAGINE S3 */}
          <div className="mt-3">
            <label
              htmlFor="coverInputS3Change"
              className="btn btn-primary  me-3 "
            >
              Scegli IMG S3
            </label>
            <input
              id="coverInputS3Change"
              type="file"
              accept="image/*"
              style={{ display: "none" }}
              onChange={onChangeSetFileS3cambia}
            />
            <Button onClick={cambiaNuovaImmagine}>Cambia immagine S3</Button>
          </div>
        </div>
      </div>

      <Modal show={showModal} onHide={() => setShowModal(false)}>
        <Modal.Header closeButton>
          <Modal.Title>Modifica Video</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form onSubmit={handleEditAccount}>
            <Form.Group controlId="editTitolo">
              <Form.Label className="ms-2 fw-bold">Titolo</Form.Label>
              <Form.Control
                type="text"
                name="titolo"
                value={formData.titolo}
                onChange={handleModAccount}
              />
            </Form.Group>

            <Form.Group controlId="editDescizioneBreve">
              <Form.Label className="mt-3 fw-bold">
                Descrizione breve
              </Form.Label>
              <Form.Control
                type="text"
                name="descrizioneBreve"
                value={formData.descrizioneBreve}
                onChange={handleModAccount}
              />
            </Form.Group>

            <Form.Group className="mt-3">
              <Form.Label className="fw-bold">Corso</Form.Label>
              <Form.Control
                required
                size="lg"
                as="select"
                name="corso"
                value={formData.corso}
                onChange={handleModAccount}
              >
                {/* <option value={formData.corso} disabled hidden>
                  {video.video?.corso.titolo}
                </option> */}
                {
                  //logica per generare le opzioni in base ai corsi creati
                  //selezionando un titolo si seleziona l'id del corso corrispondente
                  corso.courses &&
                    corso.courses.map((course) => (
                      <option key={course._id} value={course._id}>
                        {course.titolo}
                      </option>
                    ))
                }
              </Form.Control>
            </Form.Group>

            <Form.Label className="ms-2 mt-3 fw-bold">Descrizione</Form.Label>
            <ReactQuill
              theme="snow"
              placeholder={"Descrizione..."}
              value={formData.descrizione}
              onChange={handleQuillChange}
              modules={{
                toolbar: [
                  [{ header: [1, 2, false] }],
                  ["bold", "italic", "underline", "strike", "blockquote"],
                  [{ color: [] }, { background: [] }],
                  [{ list: "ordered" }, { list: "bullet" }],
                  ["link", "image", "video"],
                  ["clean"],
                ],
              }}
            />

            <Button className="mt-3 btn-success" type="submit">
              Salva Modifiche
            </Button>
          </Form>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default GestionaleVideoID;
