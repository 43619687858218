import React, { useEffect } from "react";
import { useMemo, useState } from "react";
import { AgGridReact } from "ag-grid-react"; // React Data Grid Component
import "ag-grid-community/styles/ag-grid.css"; // Mandatory CSS required by the Data Grid
import "ag-grid-community/styles/ag-theme-quartz.css"; // Optional Theme applied to the Data Grid
import AxiosClient from "../client/client";
import { useParams } from "react-router-dom";
import useSession from "../hooks/useSession";
import AlertMessage from "../components/alertMessage/AlertMessage";
import { PuffLoader } from "react-spinners";
import CircleAccCashback from "../assets/CircleAccCashback.png";
import CircleBlackFriday from "../assets/CircleBlackFriday.png";
import CircleTeamExp from "../assets/CircleTeamExp.png";
import { Button, Col, Container, Form, Modal, Row } from "react-bootstrap";

const AziendaInfo = () => {
  const { id } = useParams();
  const client = new AxiosClient();
  const session = useSession();
  const [successMessage, setSuccessMessage] = useState(null);
  const [failedMessage, setFailedMessage] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [ricarica, setRicarica] = useState(false);
  const [data, setData] = useState([]);
  const [dataCalcolo, setDataCalcolo] = useState([]);
  const [openPDF, setOpenPDF] = useState(false);
  const [dataToken, setDataToken] = useState([]);
  const [uploadToken, setUploadToken] = useState({});
  const [token, setToken] = useState(null);
  const [openModal, setOpenModal] = useState(false);
  // const [dataSpesaCashback, setDataSpesaCashback] = useState("");
  const [show, setShow] = useState(false);

  const [message, setMessage] = useState("");
  const [endPoint, setEndPoint] = useState("");
  const [accordoCashback, setAccordoCashback] = useState(false);
  const [messageConfirm, setMessageConfirm] = useState("");

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const [dataSpesaCashback, setDataSpesaCashback] = useState({
    check1: "",
    check2: "",
    check3: "",
    check4: "",
    check5: "",
    altro: "",
  });

  const textCheck1 = "Formazione";
  const textCheck2 =
    "Consulenza (Implementazione Vault, affiancamento operativo)";
  const textCheck3 = "Assistenza CAD";
  const textCheck4 = "Configuratore CAD";
  const textCheck5 = "Altro";

  const handleOpenModal = () => setOpenModal(true);
  const [variant, setVariant] = useState("");
  const [nomeButton, setNomeButton] = useState("");

  const openModalPDF = (booleana) => {
    if (booleana === true) {
      setNomeButton("Accetta condizioni");
      setVariant("success");
      setEndPoint("Iscrizione");
      setMessageConfirm("Benvenutə in SymbioTech Rewards!");
      setAccordoCashback(true);
      setMessage(
        "Sei sicuro di voler aderire al programma SymbioTech Rewards?"
      );
    } else {
      setNomeButton("Annulla condizioni");
      setVariant("danger");
      setEndPoint("Disdetta");
      setMessageConfirm("SymbioTech Rewards annullato con successo!");
      setAccordoCashback(false);
      setMessage(
        "Continuando verrà annullata la tua iscrizione al programma SymbioTech Rewards, e come da regolamento, il credito maturato verrà azzerato. Vuoi continuare? Se hai qualche dubbio o necessità contattaci prima di procedere."
      );
    }
    handleShow();
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await client.get(`/account/${id}`);
        setData(response);
        if (response.account && response.account.storico) {
          // Mappa lo storico per popolare rowData
          const mappedData = response.account.storico.map((entry) => ({
            data: entry.data,
            nrFattura: entry.nrFattura,
            descrizione: entry.descrizione,
            imponibile: `${entry.imponibile} €`,
            cashbackMaturato: `${entry.cashbackPositivo} €`,
            cashbackSpeso: `${entry.cashbackNegativo} €`,
          }));
          setRowData(mappedData);
          setRicarica(false);
        } else {
          console.error("Storico non trovato nell'account.");
        }
      } catch (error) {
        console.error("Errore nel caricamento dei dati dell'account:", error);
      }
    };

    fetchData();
  }, [ricarica]);

  // const [rowData, setRowData] = useState([
  //   {
  //     data: "08/08/2024",
  //     nrFattura: "1",
  //     descrizione: "Asd",
  //     imponibile: 123,
  //     cashback: "10",
  //   },
  //   {
  //     data: "23/08/2024",
  //     nrFattura: "2",
  //     descrizione: "Qwe",
  //     imponibile: 456,
  //     cashback: "20",
  //   },
  //   {
  //     data: "30/08/2024",
  //     nrFattura: "3",
  //     descrizione: "Tre",
  //     imponibile: 789,
  //     cashback: "30",
  //   },
  // ]);

  const [rowData, setRowData] = useState([]);

  const defaultColDef = useMemo(() => ({ flex: 1 }));

  const [colDefs, setColDefs] = useState([
    { field: "data" },
    { field: "nrFattura" },
    { field: "descrizione" },
    { field: "imponibile" },
    { field: "cashbackMaturato" },
    { field: "cashbackSpeso" },
  ]);

  // const fetchData = async () => {
  //   try {
  //     const response = await client.get(`/account/${id}`);
  //     setData(response);
  //     if (data.storico) {
  //       // Mappa lo storico per popolare rowData
  //       const mappedData = data.storico?.map((entry) => ({
  //         data: entry.data,
  //         nrFattura: entry.nrFattura,
  //         descrizione: entry.descrizione,
  //         imponibile: entry.imponibile,
  //         cashback: entry.cashback,
  //       }));
  //       setRowData(mappedData);
  //     } else {
  //       console.error("Storico non trovato nell'account.");
  //     }
  //   } catch (error) {
  //     console.error("Errore nel caricamento dei dati dell'account:", error);
  //   }
  // };

  // // Funzione per caricare i dati dall'API
  // useEffect(() => {
  //   fetchData();
  // }, []);

  ///////////////////////////////

  const [formData, setFormData] = useState({
    data: "",
    nrFattura: "",
    descrizione: "",
    imponibile: 0,
    cashbackPositivo: 0,
    cashbackNegativo: 0,
  });

  const handleChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });
  };

  const [totCashback, setTotCashback] = useState({
    totCashback: 0,
  });

  const calcoloCashabackTotale = () => {
    let risultato = 0;
    data.account?.storico.map((data) => {
      let positivo = data.cashbackPositivo;
      let negativo = data.cashbackNegativo;

      if (positivo != 0) {
        risultato += positivo;
      }
      if (negativo != 0) {
        risultato -= negativo;
      }
    });
    return risultato;
  };

  // const dataPerCalcolo = async () => {
  //   try {
  //     const response = await client.get(`/account/${id}`);
  //     setDataCalcolo(response);
  //   } catch (error) {
  //     console.log(error);
  //   }
  // };

  // useEffect(() => {
  //   dataPerCalcolo();
  // }, []);

  // const calcoloCashabackTotale = async () => {
  //   let risultato = 0;
  //   dataCalcolo.account?.storico.map((data) => {
  //     let positivo = data.cashbackPositivo;
  //     let negativo = data.cashbackNegativo;

  //     if (positivo != 0) {
  //       risultato += positivo;
  //     }
  //     if (negativo != 0) {
  //       risultato -= negativo;
  //     }
  //   });
  //   return risultato;
  // };

  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      // Fai la prima chiamata API per aggiornare lo storico sul server
      const response = await client.patch(
        `/account/addStorico/${id}`,
        formData,
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      );

      // Reset del form e stato locale
      setRicarica(true);
      setFormData({
        data: "",
        nrFattura: "",
        descrizione: "",
        imponibile: 0,
        cashbackPositivo: 0,
        cashbackNegativo: 0,
      });

      // // Ora che hai i dati aggiornati, ricalcola il cashback totale
      // const risultatoCashbackTot = calcoloCashabackTotale(updatedData);
      // console.log("Cashback totale calcolato:", risultatoCashbackTot);

      // // Imposta il nuovo valore di totCashback
      // setTotCashback({
      //   totCashback: risultatoCashbackTot,
      // });

      // // Invia il cashback aggiornato al server
      // const responseUpdate = await client.patch(
      //   `/account/updateTotCashback/${id}`,
      //   { totCashback: risultatoCashbackTot },
      //   {
      //     headers: {
      //       "Content-Type": "application/json",
      //     },
      //   }
      // );
    } catch (error) {
      console.error("Errore durante l'aggiornamento:", error);
      alert("Errore durante l'aggiornamento dell'account.");
    }
  };

  // const handleSubmit = async (e) => {
  //   e.preventDefault();
  //   try {
  //     const response = await client.patch(
  //       `/account/addStorico/${id}`,
  //       formData,
  //       {
  //         headers: {
  //           "Content-Type": "application/json",
  //         },
  //       }
  //     );

  //     setRicarica(true);
  //     // setFormData({
  //     //   data: "",
  //     //   nrFattura: "",
  //     //   descrizione: "",
  //     //   imponibile: 0,
  //     //   cashbackPositivo: 0,
  //     //   cashbackNegativo: 0,
  //     // });
  //   } catch (error) {
  //     console.error("Error:", error);
  //     alert("Error updating account.");
  //   }
  //   try {
  //     let risultatoCashbackTot = calcoloCashabackTotale();
  //     let asdasdsad;
  //     ///////
  //     if (formData.cashbackPositivo != 0) {
  //       asdasdsad = risultatoCashbackTot += formData.cashbackPositivo;
  //     }
  //     ///////

  //     //console.log(risultatoCashbackTot);

  //     // setTotCashback({
  //     //   totCashback: risultatoCashbackTot,
  //     // });
  //     // console.log(totCashback);

  //     // const response = await client.patch(
  //     //   `/account/updateTotCashback/${id}`,
  //     //   totCashback,
  //     //   {
  //     //     headers: {
  //     //       "Content-Type": "application/json",
  //     //     },
  //     //   }
  //     // );
  //     setTotCashback({
  //       totCashback: asdasdsad,
  //     });
  //     console.log(asdasdsad); // logga il nuovo valore

  //     // Usa direttamente risultatoCashbackTot nella chiamata API
  //     const response = await client.patch(
  //       `/account/updateTotCashback/${id}`,
  //       { totCashback: asdasdsad },
  //       {
  //         headers: {
  //           "Content-Type": "application/json",
  //         },
  //       }
  //     );
  //   } catch (error) {
  //     console.log("PUTTANA EVA");
  //   }
  // };

  /////////////////////////

  const handleChangeTotCashback = (e) => {
    setTotCashback({
      ...totCashback,
      [e.target.name]: e.target.value,
    });
  };

  // const handleSubmitTotCashback = async (e) => {
  //   e.preventDefault();
  //   try {
  //     const response = await client.patch(
  //       `/account/updateTotCashback/${id}`,
  //       totCashback,
  //       {
  //         headers: {
  //           "Content-Type": "application/json",
  //         },
  //       }
  //     );
  //     setRicarica(true);
  //     setTotCashback({
  //       totCashback: 0,
  //     });
  //   } catch (error) {
  //     console.error("Error:", error);
  //     alert("Error updating account.");
  //   }
  // };
  const handleSubmitTotCashback = async (e) => {
    // Ricalcola il cashback totale
    const risultatoCashbackTot = calcoloCashabackTotale();
    console.log("Cashback totale calcolato:", risultatoCashbackTot);

    // Imposta il nuovo valore di totCashback
    setTotCashback({
      totCashback: risultatoCashbackTot,
    });

    try {
      const response = await client.patch(
        `/account/updateTotCashback/${id}`,
        { totCashback: risultatoCashbackTot }, // Usa direttamente il valore calcolato
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      );
      setRicarica(true);

      // Resetta il valore di totCashback
      setTotCashback({
        totCashback: 0,
      });
    } catch (error) {
      console.error("Error:", error);
      alert("Error updating account.");
    }
  };

  // const handleSubmitTotCashback = async (e) => {
  //   // Ora che hai i dati aggiornati, ricalcola il cashback totale
  //   const risultatoCashbackTot = calcoloCashabackTotale();
  //   console.log("Cashback totale calcolato:", risultatoCashbackTot);

  //   // Imposta il nuovo valore di totCashback
  //   setTotCashback({
  //     totCashback: risultatoCashbackTot,
  //   });

  //   //////

  //   try {
  //     const response = await client.patch(
  //       `/account/updateTotCashback/${id}`,
  //       totCashback,
  //       {
  //         headers: {
  //           "Content-Type": "application/json",
  //         },
  //       }
  //     );
  //     setRicarica(true);
  //     setTotCashback({
  //       totCashback: 0,
  //     });
  //   } catch (error) {
  //     console.error("Error:", error);
  //     alert("Error updating account.");
  //   }
  // };

  // const handleCompleteSubmit = async (e) => {
  //   e.preventDefault(); // Previene il comportamento predefinito del form se necessario
  //   //setIsLoading(true);
  //   // Esegui la funzione handleSubmit
  //   try {
  //     await handleSubmit(e); // Attendi il completamento della prima funzione

  //     // // Dopo che handleSubmit è stata completata, esegui handleSubmitTotCashback
  //     // await handleSubmitTotCashback(e);

  //     // console.log("Aggiornamento completato con successo.");
  //     // setIsLoading(false);
  //     // setSuccessMessage("Fatto!");
  //     // setTimeout(async () => {
  //     //   await handleSubmitTotCashback(e);
  //     //   setSuccessMessage(null);
  //     // }, 3000);
  //   } catch (error) {
  //     setFailedMessage("Errore!");
  //     setTimeout(() => {
  //       setFailedMessage(null);
  //     }, 3000);
  //     console.error("Errore durante l'esecuzione del submit:", error);
  //   }
  //   // Imposta un timeout di 5 secondi prima di eseguire handleSubmitTotCashback
  //   setTimeout(async () => {
  //     await handleSubmitTotCashback(e); // Esegui la seconda funzione dopo 5 secondi
  //     console.log("Aggiornamento completato con successo.");
  //   }, 2000); // 5000 millisecondi = 5 secondi
  // };

  const getUploadToken = async () => {
    const response = await fetch(
      `${process.env.REACT_APP_SERVER_BASE_URL}/uploadToken/${session.id}`,
      {
        headers: {
          "Content-Type": "application/json",
        },
        method: "POST",
        body: JSON.stringify(uploadToken),
      }
    );

    const data = await response.json();

    if (data.token) {
      localStorage.setItem("loggedInUser", JSON.stringify(data.token));
    }
    setToken(data);
  };

  const pdf = "../../pdf/SymbioTech Rewards.pdf";

  //TOGLIERE
  const handleOpenPDF = () => {
    window.open(
      "../../pdf/SymbioTech Rewards.pdf",
      "_blank",
      "noopener,noreferrer"
    );
    setOpenPDF(true);
  };

  const ModAccordoCashback = async () => {
    const confirm = window.confirm(`${message}`);

    if (confirm) {
      setIsLoading(true);
      try {
        const response = await client.patch(
          `/account/update/${session.id}`,
          { accordoCashback: accordoCashback },
          {
            headers: {
              "Content-Type": "application/json",
            },
          }
        );

        if (response.statusCode === 200) {
          getUploadToken();
          setIsLoading(false);
          handleClose();
          setSuccessMessage(`${messageConfirm}`);
          setTimeout(() => {
            setSuccessMessage(null);
          }, 3000);
        }
      } catch (error) {
        setIsLoading(false);
        setFailedMessage("Errore nella richiesta al server");
        setTimeout(() => {
          setFailedMessage(null);
        }, 3000);
        console.error(error);
      }
      try {
        const response = await client.post(
          `/email${endPoint}Cashback/${session.id}`
        );
        console.log("Email inviata:", response);
      } catch (error) {
        console.error("Errore nell'invio dell'email:", error);
      }
    }
    setOpenPDF(false);
  };

  //INVIA RICHIESTA SPESA DAL MODALE
  const inviaRichiestaSpesa = async () => {
    setIsLoading(true);
    try {
      const response = await client.post(
        `/inviaEmailSpesaCashback/${session.id}`,
        { dataSpesaCashback }
      );

      setOpenModal(false);
      setIsLoading(false);
      console.log("Dati modificati con successo");
      console.log("Email inviata:", response);
      setDataSpesaCashback({
        check1: "",
        check2: "",
        check3: "",
        check4: "",
        check5: "",
        altro: "",
      });
      setSuccessMessage("Richiesta inviata con successo!");
      setTimeout(() => {
        setSuccessMessage(null);
      }, 3000);
    } catch (error) {
      console.error("Errore nell'invio dell'email:", error);
      setIsLoading(false);
      setFailedMessage("Errore nella richiesta al server");
      setTimeout(() => {
        setFailedMessage(null);
      }, 3000);
    }
  };

  return (
    <>
      {successMessage && (
        <div>
          <AlertMessage message={successMessage} success={true} />
        </div>
      )}

      {failedMessage && (
        <div>
          <AlertMessage message={failedMessage} success={false} />
        </div>
      )}

      {isLoading && (
        <div className="alert-container">
          <PuffLoader color="#ec800f" size={200} />
        </div>
      )}

      {session.role === "azienda" && session.accordoCashback === false && (
        <Container>
          <h1 className="margin_T120">SymbioTech Rewards</h1>
          <p className="mt-5">
            Accumula crediti e investi nella tua crescita professionale con
            SymbioTech È facile far crescere il tuo business con{" "}
            <strong>SymbioTech Rewards!</strong> <br /> Ogni licenza Autodesk e
            servizio acquistato ti fa guadagnare credito (cashback) che puoi
            utilizzare per ottenere sconti su future attività.
          </p>
          <h5 className="mt-4">Quali sono i tuoi vantaggi?</h5>
          <p>
            Riscatta il tuo credito su un’ampia gamma di prodotti e servizi
            SymbioTech per ridurre i costi di una nuova formazione o consulenza.
          </p>

          {/* PALLA 1 */}
          {/* SCHERMO GRANDE */}
          <div className="d-none d-lg-flex d-xxl-flex">
            <div className="d-flex justify-content-start align-items-center">
              <img
                className="m-3 ms-0 me-5 circleImg"
                src={CircleAccCashback}
                alt=""
              />
              <p>
                <strong>Guadagna credito con ogni acquisto</strong> <br />
                Ogni volta che concludi un acquisto con SymbioTech, comprese le
                soluzioni di Autodesk e di altre aziende di software, accumulate
                punti fedeltà. <br /> Più acquisti, più credito guadagni!
              </p>
            </div>
          </div>
          {/* palla 2 */}
          {/* <div className="d-none d-lg-flex d-xxl-flex">
            <div className="d-flex justify-content-start align-items-center">
              <img
                onClick={() => openModalPDF(true)}
                className="m-3 ms-0 me-5 circleImgLink"
                src={CircleBlackFriday}
                alt=""
              />
              <div>
                <p>
                  <strong>Black Friday esplosivo con SymbioTech!</strong>
                </p>
                <ul>
                  Cosa devi fare?
                  <br />
                  <li>Entra nell'area SymbioTech Rewards.</li>
                  <li>
                    Attiva il programma: in pochi click otterrai subito i tuoi
                    50€.
                  </li>
                  <li>Goditi il 5% di cashback su tutti i servizi</li>
                </ul>
              </div>
            </div>
          </div> */}

          {/* SCHERMO PICCOLO */}
          {/* PALLA 1 */}
          <div className="d-lg-none d-flex flex-wrap justify-content-center">
            <img className="m-3 circleImg" src={CircleAccCashback} alt="" />
            <p>
              <strong>Guadagna credito con ogni acquisto</strong> <br />
              Ogni volta che concludi un acquisto con SymbioTech, comprese le
              soluzioni di Autodesk e di altre aziende di software, accumulate
              punti fedeltà. <br /> Più acquisti, più credito guadagni!
            </p>
          </div>
          {/* PALLA 2 */}
          {/* <div className="d-lg-none d-flex flex-wrap justify-content-center">
            <img
              className="m-3 circleImgLink"
              src={CircleBlackFriday}
              alt=""
              onClick={() => openModalPDF(true)}
            />
            <p>
              <strong>Black Friday esplosivo con SymbioTech!</strong>
            </p>
            <ul>
              Cosa devi fare?
              <br />
              <li>Entra nell'area SymbioTech Rewards.</li>
              <li>
                Attiva il programma: in pochi click otterrai subito i tuoi 50€.
              </li>
              <li>Goditi il 5% di cashback su tutti i servizi</li>
            </ul>
          </div> */}

          {/* SCHERMO GRANDE */}
          <div className="mt-4 mb-5 d-flex align-items-center d-none d-lg-flex d-xxl-flex">
            <span className="me-1">*Guarda e accetta </span>
            <span
              onClick={() => openModalPDF(true)}
              style={{
                color: "orange",
                textDecoration: "underline",
                cursor: "pointer",
              }}
            >
              le condizioni del programma SymbioTech Rewards
            </span>
            <span className="ms-1">
              per visualizzare le tue transazioni e il credito accumulato
            </span>
          </div>

          {/* SCHERMO PICCOLO */}
          <div className="mt-4 mb-5 d-lg-none">
            <span className="me-1">*Guarda e accetta </span>
            <span
              onClick={() => openModalPDF(true)}
              style={{
                color: "orange",
                textDecoration: "underline",
                cursor: "pointer",
              }}
            >
              le condizioni del programma SymbioTech Rewards
            </span>
            <span className="ms-1">
              per visualizzare le tue transazioni e il credito accumulato
            </span>
          </div>
        </Container>
      )}

      {/* POST ACCETTAZIONE ACCORDO */}
      {((session.role === "azienda" && session.accordoCashback === true) ||
        session.role === "admin") && (
        <>
          <h3 className="margin_T120 mb-4 text-center">
            Benvenuti nella tua Area Clienti dedicata.
          </h3>
          <p className="container text-center">
            Qui potrai consultare in modo semplice e intuitivo lo storico dei
            tuoi acquisti, monitorare l'ammontare del cashback accumulato e
            scoprire come sfruttarlo al meglio. Grazie al nostro programma, hai
            l'opportunità di ridurre fino al 30% le spese relative a consulenze,
            formazione e installazioni. <br /> Scopri come convertire il tuo
            cashback in un vantaggio concreto per la tua azienda.
          </p>

          <h3 className="mt-4 mb-3 text-center">
            Totale Cashback*: {data.account?.totCashback} €
          </h3>
          <div className="mb-3 d-flex justify-content-center align-items-center">
            <Button onClick={handleOpenModal}>
              Invia da qui la tua richiesta
            </Button>
          </div>
          <div className="ag-theme-quartz  mb-5" style={{ height: 400 }}>
            <AgGridReact
              rowData={rowData}
              columnDefs={colDefs}
              rowSelection={"multiple"}
              defaultColDef={defaultColDef}
            />
          </div>

          <Container>
            <p>
              <strong>Come visualizzare il mio cashback: </strong> Nella tabella
              riepilogativa degli acquisti, troverai una colonna specifica che
              mostra l'importo del cashback maturato per ogni transazione e come
              è stato speso ed una voce "Totale casback" <br />
              <strong>*Come spendere il mio cashback: </strong>
              Per utilizzare il tuo cashback, è sufficiente acquistare un
              servizio SymbioTech (Consulenza, Formazione ed installazioni) e al
              momento del pagamento verrà scalato il credito maturato fino ad un
              massimo del 30% per singola voce della fattura. <br />
              <strong>Durata del Cashback: </strong>
              La scadenza del cashback è fissata al termine dell'anno solare
              successivo a quello in cui è stato maturato. Ad esempio, per gli
              acquisti effettuati nel 2024, la scadenza è il 31 dicembre 2025.
            </p>
            {session.role === "azienda" && session.accordoCashback === true && (
              <>
                <div className="d-flex align-items-center my-4">
                  <span className="me-1">*Vedi </span>
                  <span
                    onClick={() => openModalPDF(false)}
                    style={{
                      color: "orange",
                      textDecoration: "underline",
                      cursor: "pointer",
                    }}
                  >
                    le condizioni del programma SymbioTech Rewards
                  </span>
                  {openPDF === true && id === session.id && (
                    <>
                      <span className="ms-3">
                        Vuoi uscire dal programma SymbioTech Rewards?
                      </span>
                    </>
                  )}
                  {/* Bottone per aprire il modale */}
                  {/* <Button variant="primary" onClick={handleShow}>
                    Leggi Accordo Contrattuale
                  </Button> */}
                </div>
              </>
            )}
          </Container>
        </>
      )}

      {/* <h3 className="margin_T120 mb-4 text-center">
        Benvenuti nella tua Area Clienti dedicata.
      </h3>
      <p className="container text-center">
        Qui potrai consultare in modo semplice e intuitivo lo storico dei tuoi
        acquisti, monitorare l'ammontare del cashback accumulato e scoprire come
        sfruttarlo al meglio. Grazie al nostro programma, hai l'opportunità di
        ridurre fino al 30% le spese relative a consulenze, formazione e
        installazioni. Scopri come convertire il tuo cashback in un vantaggio
        concreto per la tua azienda.
      </p>

      <h3 className="my-4 text-center">
        Totale Cashback*: {data.account?.totCashback} €
      </h3>
      <div className="ag-theme-quartz  mb-5" style={{ height: 400 }}>
        <AgGridReact
          rowData={rowData}
          columnDefs={colDefs}
          rowSelection={"multiple"}
          defaultColDef={defaultColDef}
        />
      </div>

      <Container>
        <p>
          <strong>Come visualizzare il mio cashback: </strong> Nella tabella
          riepilogativa degli acquisti, troverai una colonna specifica che
          mostra l'importo del cashback maturato per ogni transazione e come è
          stato speso ed una voce "Totale casback" <br />
          <strong>*Come spendere il mio cashback: </strong>
          Per utilizzare il tuo cashback, è sufficiente acquistare un servizio
          SymbioTech (Consulenza, Formazione ed installazioni) e al momento del
          pagamento verrà scalato il credito maturato fino ad un massimo del 30%
          per singola voce della fattura. <br />
          <strong>Durata del Cashback: </strong>
          La scadenza del cashback è fissata al termine dell'anno solare
          successivo a quello in cui è stato maturato. Ad esempio, per gli
          acquisti effettuati nel 2024, la scadenza è il 31 dicembre 2025.
        </p>
      </Container> */}

      {/* <form onSubmit={handleSubmit}>
        <div>
          <label>Data:</label>
          <input
            type="text"
            name="data"
            value={formData.data}
            onChange={handleChange}
          />
        </div>
        <div>
          <label>Nr Fattura:</label>
          <input
            type="text"
            name="nrFattura"
            value={formData.nrFattura}
            onChange={handleChange}
          />
        </div>
        <div>
          <label>Descrizione:</label>
          <input
            type="text"
            name="descrizione"
            value={formData.descrizione}
            onChange={handleChange}
          />
        </div>
        <div>
          <label>Imponibile:</label>
          <input
            type="text"
            name="imponibile"
            value={formData.imponibile}
            onChange={handleChange}
          />
        </div>
        <div>
          <label>Cashback:</label>
          <input
            type="text"
            name="cashback"
            value={formData.cashback}
            onChange={handleChange}
          />
        </div>
        <button type="submit">Update Account</button>
      </form> */}

      {session.role === "admin" && (
        <Container className="my-5">
          <h4 className="text-center mt-5 mb-2">Aggiorna totale cashback</h4>
          <Form onSubmit={handleSubmitTotCashback}>
            <Row className="mt-3 justify-content-center">
              <Form.Group as={Col} md="1">
                <Form.Label className="fw-bold">Precedente</Form.Label>
                <Form.Label className="fw-bold mt-1 d-flex justify-content-center">
                  {data.account?.totCashback} €
                </Form.Label>
              </Form.Group>
              {/* <Form.Group as={Col} md="1">
                <Form.Label className="fw-bold">Nuovo</Form.Label>
                <Form.Control
                  required
                  placeholder="0"
                  type="number"
                  name="totCashback"
                  value={totCashback.totCashback}
                  onChange={handleChangeTotCashback}
                />
              </Form.Group> */}
              <Form.Group as={Col} md="1" className="mt-4">
                <Button
                  onClick={handleSubmitTotCashback}
                  size="lg"
                  variant="dark"
                  style={{
                    marginLeft: "10px",
                  }}
                >
                  Aggiorna
                </Button>
              </Form.Group>
            </Row>
          </Form>

          <h4 className="text-center mt-5 mb-2">Aggiungi movimento</h4>
          <Form onSubmit={handleSubmit}>
            <Row className="mt-3">
              <Form.Group as={Col} md="2">
                <Form.Label className="fw-bold">Data</Form.Label>
                <Form.Control
                  required
                  placeholder="Data"
                  type="text"
                  name="data"
                  value={formData.data}
                  onChange={handleChange}
                />
              </Form.Group>
              <Form.Group as={Col} md="2">
                <Form.Label className="fw-bold">Nr. Fattura</Form.Label>
                <Form.Control
                  required
                  placeholder="Numero Fattura"
                  type="text"
                  name="nrFattura"
                  value={formData.nrFattura}
                  onChange={handleChange}
                />
              </Form.Group>
              {/* <Form.Group as={Col} md="4">
                <Form.Label className="fw-bold">Descrizione</Form.Label>
                <Form.Control
                  required
                  size="md"
                  placeholder="Descrizione"
                  type="text"
                  name="descrizione"
                  value={formData.descrizione}
                  onChange={handleChange}
                />
              </Form.Group> */}
              <Form.Group as={Col} md="2">
                <Form.Label className="fw-bold">Imponibile</Form.Label>
                <Form.Control
                  required
                  placeholder="Imponibile"
                  type="number"
                  name="imponibile"
                  value={formData.imponibile}
                  onChange={handleChange}
                />
              </Form.Group>
              <Form.Group as={Col} md="2">
                <Form.Label className="fw-bold">Cashback Maturato</Form.Label>
                <Form.Control
                  required
                  type="number"
                  name="cashbackPositivo"
                  value={formData.cashbackPositivo}
                  onChange={handleChange}
                />
              </Form.Group>
              <Form.Group as={Col} md="2">
                <Form.Label className="fw-bold">Cashback Speso</Form.Label>
                <Form.Control
                  required
                  type="number"
                  name="cashbackNegativo"
                  value={formData.cashbackNegativo}
                  onChange={handleChange}
                />
              </Form.Group>
            </Row>
            <Form.Group className="mt-2">
              <Form.Label className="fw-bold">Descrizione</Form.Label>
              <Form.Control
                required
                size="md"
                placeholder="Descrizione"
                type="text"
                name="descrizione"
                value={formData.descrizione}
                onChange={handleChange}
              />
            </Form.Group>
            <Form.Group className="d-flex mt-4 justify-content-end">
              <Button
                type="submit"
                size="lg"
                variant="dark"
                style={{
                  marginLeft: "1em",
                }}
              >
                Invia
              </Button>
            </Form.Group>
          </Form>

          {/* <button onClick={handleSubmitTotCashback}>
            Aggiorna Totale Cashback
          </button> */}
          {/* <div>
            <button onClick={handleCompleteSubmit}>
              Completa Aggiornamento Cashback
            </button>
          </div> */}
        </Container>
      )}

      <Modal show={openModal} onHide={() => setOpenModal(false)}>
        <Modal.Header closeButton>
          <Modal.Title>Invia form richiesta</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form.Group controlId="formSpesaCashback">
            <Form.Label>
              Indicaci la tua esigenza e verrai contatto dal nostro commerciale
            </Form.Label>
            <div>
              <Form.Check
                className="mt-3"
                type="checkbox"
                id="check1"
                label={textCheck1}
                checked={dataSpesaCashback.check1 === `${textCheck1}`}
                onChange={(e) =>
                  setDataSpesaCashback((prev) => ({
                    ...prev,
                    check1: e.target.checked ? `${textCheck1}` : "",
                  }))
                }
              />
              <Form.Check
                className="mt-1"
                type="checkbox"
                id="check2"
                label={textCheck2}
                checked={dataSpesaCashback.check2 === `${textCheck2}`}
                onChange={(e) =>
                  setDataSpesaCashback((prev) => ({
                    ...prev,
                    check2: e.target.checked ? `${textCheck2}` : "",
                  }))
                }
              />
              <Form.Check
                className="mt-1"
                type="checkbox"
                id="check3"
                label={textCheck3}
                checked={dataSpesaCashback.check3 === `${textCheck3}`}
                onChange={(e) =>
                  setDataSpesaCashback((prev) => ({
                    ...prev,
                    check3: e.target.checked ? `${textCheck3}` : "",
                  }))
                }
              />
              <Form.Check
                className="mt-1"
                type="checkbox"
                id="check4"
                label={textCheck4}
                checked={dataSpesaCashback.check4 === `${textCheck4}`}
                onChange={(e) =>
                  setDataSpesaCashback((prev) => ({
                    ...prev,
                    check4: e.target.checked ? `${textCheck4}` : "",
                  }))
                }
              />
              <Form.Check
                className="mt-1"
                type="checkbox"
                id="check5"
                label={textCheck5}
                checked={dataSpesaCashback.check5 === `${textCheck5}`}
                onChange={(e) =>
                  setDataSpesaCashback((prev) => ({
                    ...prev,
                    check5: e.target.checked ? `${textCheck5}` : "",
                  }))
                }
              />
            </div>
            <Form.Group controlId="altroInput" className="mt-4">
              <Form.Label>Specifica le tue esigenze:</Form.Label>
              <Form.Control
                as="textarea"
                rows={3}
                value={dataSpesaCashback.altro}
                onChange={(e) =>
                  setDataSpesaCashback((prev) => ({
                    ...prev,
                    altro: e.target.value,
                  }))
                }
              />
            </Form.Group>
          </Form.Group>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => setOpenModal(false)}>
            Chiudi
          </Button>
          <Button variant="primary" onClick={inviaRichiestaSpesa}>
            Invia
          </Button>
        </Modal.Footer>
      </Modal>

      {/* MODALE PER PDF */}
      <Modal show={show} onHide={handleClose} size="lg" centered>
        <Modal.Header closeButton>
          <Modal.Title>Condizioni programma SymbioTech Rewards</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {/* Visualizzazione PDF */}
          <iframe
            src={pdf}
            title="Accordo Contrattuale"
            style={{ width: "100%", height: "500px", border: "none" }}
          />
        </Modal.Body>
        <Modal.Footer>
          <Button
            variant={variant}
            className="ms-3"
            onClick={() => ModAccordoCashback(true)}
          >
            {nomeButton}
          </Button>
          <Button variant="secondary" onClick={handleClose}>
            Chiudi
          </Button>
        </Modal.Footer>
      </Modal>

      {/* <Modal show={openModal} onHide={() => setOpenModal(false)}>
        <Modal.Header closeButton>
          <Modal.Title>Invia richeista spesa cashback</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form.Group controlId="formSpesaCashback">
            <Form.Label>
              Seleziona uno dei punti altrimenti specifica in altro
            </Form.Label>
            <Form.Control
              as="check"
              rows={3}
              value={dataSpesaCashback.check1}
              onChange={(e) => setDataSpesaCashback(e.target.value)}
            />
            <Form.Control
              as="check"
              rows={3}
              value={dataSpesaCashback.check2}
              onChange={(e) => setDataSpesaCashback(e.target.value)}
            />
            <Form.Control
              as="check"
              rows={3}
              value={dataSpesaCashback.check3}
              onChange={(e) => setDataSpesaCashback(e.target.value)}
            />
            <Form.Control
              as="check"
              rows={3}
              value={dataSpesaCashback.check4}
              onChange={(e) => setDataSpesaCashback(e.target.value)}
            />
            <Form.Control
              as="check"
              rows={3}
              value={dataSpesaCashback.check5}
              onChange={(e) => setDataSpesaCashback(e.target.value)}
            />
            <Form.Control
              as="textarea"
              rows={3}
              value={dataSpesaCashback.altro}
              onChange={(e) => setDataSpesaCashback(e.target.value)}
            />
          </Form.Group>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => setOpenModal(false)}>
            Chiudi
          </Button>
          <Button variant="primary" onClick={inviaRichiestaSpesa}>
            Invia
          </Button>
        </Modal.Footer>
      </Modal> */}

      {/* <Container className="my-5">
        <h4 className="text-center mt-5 mb-2">Aggiungi movimento</h4>
        <Form onSubmit={handleSubmit}>
          <Form.Group>
            <Form.Label className="fw-bold">Descrizione</Form.Label>
            <Form.Control
              required
              size="md"
              placeholder="Descrizione"
              type="text"
              name="descrizione"
              value={formData.descrizione}
              onChange={handleChange}
            />
          </Form.Group>
          <Row className="mt-3">
            <Form.Group as={Col} md="3">
              <Form.Label className="fw-bold">Data</Form.Label>
              <Form.Control
                required
                placeholder="Data"
                type="text"
                name="data"
                value={formData.data}
                onChange={handleChange}
              />
            </Form.Group>
            <Form.Group as={Col} md="3">
              <Form.Label className="fw-bold">Numero Fattura</Form.Label>
              <Form.Control
                required
                placeholder="Numero Fattura"
                type="text"
                name="nrFattura"
                value={formData.nrFattura}
                onChange={handleChange}
              />{" "}
            </Form.Group>
            <Form.Group as={Col} md="3">
              <Form.Label className="fw-bold">Imponibile</Form.Label>
              <Form.Control
                required
                placeholder="Imponibile"
                type="text"
                name="imponibile"
                value={formData.imponibile}
                onChange={handleChange}
              />
            </Form.Group>
            <Form.Group as={Col} md="3">
              <Form.Label className="fw-bold">Cashback</Form.Label>
              <Form.Control
                required
                placeholder="Cashback"
                type="text"
                name="cashback"
                value={formData.cashback}
                onChange={handleChange}
              />
            </Form.Group>
          </Row>
          <Form.Group className="d-flex mt-4 justify-content-end">
            <Button type="reset" size="lg" variant="outline-dark">
              Reset
            </Button>
            <Button
              type="submit"
              size="lg"
              variant="dark"
              style={{
                marginLeft: "1em",
              }}
            >
              Submit
            </Button>
          </Form.Group>
        </Form>
      </Container> */}
    </>
  );
};

export default AziendaInfo;
