// import React from "react";

// const VideoCardItem = ({ titolo, cover, _id, showLink }) => {
//   return (
//     // <div className="card2 d-flex align-items-center mx-3">
//     //   <a href={`/videoId/${_id}`}>
//     //     <div className=""></div>
//     //     <img src={cover} alt="Immagine della card" />
//     //     {/* <div className="overlay">
//     //       <h1 className="fade-in">{titolo}</h1>
//     //     </div> */}
//     //   </a>
//     //   <a href={`/videoId/${_id}`}>
//     //     <h4 className="ms-3">{titolo}</h4>
//     //   </a>
//     // </div>
//     <article className="cta m-3">
//       <img src={cover} alt="cover" />
//       <div className="cta__text-column">
//         <h4>{titolo}</h4>
//         {/* <a href={`/videoId/${_id}`}>Vai al video</a> */}
//         {showLink && <a href={`/videoId/${_id}`}>Vai al video</a>}
//       </div>
//     </article>
//   );
// };

// export default VideoCardItem;

import React from "react";
import { Link } from "react-router-dom";
import { OverlayTrigger, Tooltip } from "react-bootstrap";

const VideoCardItem = ({ titolo, cover, _id, link, descrizioneBreve }) => {
  const renderTooltip = (props) => (
    <Tooltip id="button-tooltip" {...props}>
      {descrizioneBreve}
    </Tooltip>
  );
  return (
    // <Link to={`/videoId/${_id}`} className="cta m-3 clear_link_black">
    //   <img src={cover} alt="cover" />
    //   <div className="cta__text-column">
    //     <h4>{titolo}</h4>
    //     <p>{descrizioneBreve}</p>
    //   </div>
    // </Link>
    <>
      {/* ---------------------------------------------- SCHERMO GRANDE */}
      <Link
        to={`/${link}/${_id}`}
        className="cta m-3 clear_link_black d-none d-lg-flex d-xxl-flex"
      >
        <img src={cover} alt="cover" className="d-none d-lg-flex d-xxl-flex" />
        <div className="cta__text-column">
          <h4>{titolo}</h4>
          <hr />
          <div className="ctaDivEllipsis">
            <OverlayTrigger
              placement="top"
              delay={{ show: 300, hide: 100 }}
              overlay={renderTooltip}
            >
              <p className="ctaEllisse">{descrizioneBreve}</p>
            </OverlayTrigger>
          </div>
        </div>
      </Link>

      {/* ---------------------------------------------- SCHERMO PICCOLO */}
      <Link to={_id} className="cta_mobile m-3 clear_link_black d-lg-none">
        <img src={cover} alt="cover" />
        <div className="cta__text-column_mobile align-content-center">
          <h4>{titolo}</h4>
          <hr className="hr_cardVideo_mobile" />
          <div className="ctaDivEllipsis">
            <OverlayTrigger
              placement="top"
              delay={{ show: 300, hide: 100 }}
              overlay={renderTooltip}
            >
              <p className="ctaEllisse">{descrizioneBreve}</p>
            </OverlayTrigger>
          </div>
        </div>
      </Link>
    </>
  );
};

export default VideoCardItem;
