import React, { useState } from "react";
import { Button, Container, Form } from "react-bootstrap";
//import "./styles.css";
//import MainLayout from "../";
import AlertMessage from "../components/alertMessage/AlertMessage";
import { PuffLoader } from "react-spinners";
import AxiosClient from "../client/client";
import { Axios } from "axios";
import { EyeFill } from "react-bootstrap-icons";
//import { useNavigate } from "react-router-dom";

const PassDimenticata = () => {
  const client = new AxiosClient();
  const [successMessage, setSuccessMessage] = useState(null);
  const [failedMessage, setFailedMessage] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [showPassword2, setShowPassword2] = useState(false);

  const [formData, setFormData] = useState({
    email: "",
    newData: "",
    confirmPass: "", // Aggiunto il campo per la conferma della password
  });

  const [file, setFile] = useState(null);

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  const togglePasswordVisibility2 = () => {
    setShowPassword2(!showPassword2);
  };

  const onChangeSetFile = (e) => {
    setFile(e.target.files[0]);
  };

  function validatePassword(newData) {
    const hasUpperCase = /[A-Z]/.test(newData);
    const hasNumber = /\d/.test(newData);
    const hasSpecialChar = /[!@#$%^&*(),.?":{}|<>]/.test(newData);

    if (!hasUpperCase || !hasNumber || !hasSpecialChar) {
      return false;
    }
    return true;
  }

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const onSubmit = async (e) => {
    e.preventDefault();

    if (!validatePassword(formData.newData)) {
      setFailedMessage(
        "La password deve contenere almeno una lettera maiuscola, un numero e un simbolo !@#$%^&*()?."
      );
      setTimeout(() => {
        setFailedMessage(null);
      }, 4000);
      return;
    }

    setIsLoading(true);

    if (formData.newData !== formData.confirmPass) {
      setIsLoading(false);
      setFailedMessage("Le password non corrispondono.");
      setTimeout(() => {
        setFailedMessage(null);
      }, 3000);
      return;
    }

    const formDataToSend = { ...formData };
    delete formDataToSend.confirmPass;

    try {
      const response = await client.patch(`/lostPassword`, formDataToSend, {
        headers: {
          "Content-Type": "application/json",
        },
      });

      if (response.statusCode === 201) {
        console.log("Email inviata con successo!");
        setIsLoading(false);
        setSuccessMessage("Email inviata con successo!");
        setTimeout(() => {
          setSuccessMessage(null);
          //navigate('/home')
        }, 3000);
      } else if (response.statusCode === 404) {
        console.log("Email non registrata: ", formData.email);
        setIsLoading(false);
        setSuccessMessage("Email non registrata!");
        setTimeout(() => {
          setSuccessMessage(null);
          //navigate('/home')
        }, 3000);
      } else {
        setIsLoading(false);
        console.error("Email non registrata!");
        setFailedMessage("Email non registrata!");
        setTimeout(() => {
          setFailedMessage(null);
        }, 3000);
      }
    } catch (e) {
      setIsLoading(false);
      console.error("Errore nella richiesta al server", e);
      setFailedMessage("Errore nella richiesta al server");
      setTimeout(() => {
        setFailedMessage(null);
      }, 3000);
    }
  };

  return (
    <>
      {successMessage && (
        <div>
          <AlertMessage message={successMessage} success={true} />
        </div>
      )}

      {failedMessage && (
        <div>
          <AlertMessage message={failedMessage} success={false} />
        </div>
      )}

      {isLoading && (
        <div className="alert-container">
          <PuffLoader color="#ec800f" />
        </div>
      )}

      <Container className="new-blog-container margin_T120">
        <h1 className="mb-4">Imposta una nuova Password</h1>
        <p className="my-3">
          *Una volta scelta la nuova password, verrà inviata un'email
          all'indirizzo indicato con un link per confermare la modifica. Fino
          alla conferma, la password attuale rimarrà invariata.
        </p>

        <Form encType="multipart/form-data" onSubmit={onSubmit}>
          <Form.Group controlId="email-form" className="mt-3">
            <Form.Label className="fw-bold">Email</Form.Label>
            <Form.Control
              required
              size="lg"
              type="email"
              name="email"
              onChange={handleInputChange}
              placeholder="Email"
            ></Form.Control>
          </Form.Group>

          <Form.Group controlId="newData-form" className="mt-3">
            <div className="d-flex justify-content-between">
              <Form.Label className="fw-bold">Nuova Password</Form.Label>
              <EyeFill
                role="button"
                color="black"
                size={25}
                onClick={togglePasswordVisibility}
              />
            </div>
            <Form.Control
              required
              size="lg"
              type={showPassword ? "text" : "password"}
              name="newData"
              onChange={handleInputChange}
              placeholder="Password"
            />
          </Form.Group>

          <Form.Group controlId="confirmPass-form" className="mt-3">
            <div className="d-flex justify-content-between">
              <Form.Label className="fw-bold">Conferma Password</Form.Label>
              <EyeFill
                role="button"
                color="black"
                size={25}
                onClick={togglePasswordVisibility2}
              />
            </div>
            <Form.Control
              required
              size="lg"
              type={showPassword2 ? "text" : "password"}
              name="confirmPass"
              value={formData.confirmPass}
              onChange={handleInputChange}
              placeholder="Password"
            />
          </Form.Group>

          <Form.Group className="d-flex mt-3 justify-content-end">
            <Button type="reset" size="lg" variant="outline-dark">
              Reset
            </Button>
            <Button
              type="submit"
              size="lg"
              variant="dark"
              style={{
                marginLeft: "1em",
              }}
            >
              {" "}
              Invia
            </Button>
          </Form.Group>
        </Form>
      </Container>
    </>
  );
};
export default PassDimenticata;
