import React, { useEffect, useState } from "react";
import AxiosClient from "../client/client";
import { Link } from "react-router-dom";
import ResponsivePagination from "react-responsive-pagination";
import "react-responsive-pagination/themes/classic.css";
import { Col, Container, Row } from "react-bootstrap";
import Button from "react-bootstrap/Button";
import Card from "react-bootstrap/Card";

const WebinarNoLog = () => {
  // const client = new AxiosClient();
  // const [webinars, setWebinars] = useState([]);
  // const [isLoadingAccounts, setIsLoadingAccounts] = useState(false);
  // const [currentPage, setCurrentPage] = useState(1);
  // const [searchTerm, setSearchTerm] = useState("");

  // const getWebinars = async () => {
  //   try {
  //     setIsLoadingAccounts(true);
  //     const response = await client.get(
  //       `/webinars/byTitle?title=${searchTerm}&page=${currentPage}`
  //     );
  //     setWebinars(response);
  //     setIsLoadingAccounts(false);
  //   } catch (error) {
  //     console.log(error);
  //   }
  // };

  // const handlePagination = (value) => {
  //   setCurrentPage(value);
  // };

  // const handleSearchTermChange = (newTerm) => {
  //   setSearchTerm(newTerm);
  //   setCurrentPage(1); // Imposta currentPage a 1 quando il termine di ricerca cambia
  // };

  // useEffect(() => {
  //   getWebinars();
  // }, [currentPage, searchTerm]);
  const client = new AxiosClient();
  const [webinars, setWebinars] = useState([]);
  const [webinars2, setWebinars2] = useState([]);
  const [isLoadingAccounts, setIsLoadingAccounts] = useState(false);
  const [isLoadingAccounts2, setIsLoadingAccounts2] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [currentPage2, setCurrentPage2] = useState(1);
  const [searchTerm, setSearchTerm] = useState("");
  const [searchTerm2, setSearchTerm2] = useState("");

  const getWebinars = async () => {
    try {
      setIsLoadingAccounts(true);
      const response = await client.get(
        `/webinarsCategorie/byRamo1?ramo1=${searchTerm}&page=${currentPage}`
      );
      setWebinars(response);
      setIsLoadingAccounts(false);
    } catch (error) {
      console.log(error);
    }
  };

  const getWebinars2 = async () => {
    try {
      setIsLoadingAccounts2(true);
      const response = await client.get(
        `/webinarsContenuto/byRamo234?ramo2=${searchTerm2}&page=${currentPage2}`
      );
      setWebinars2(response);
      setIsLoadingAccounts2(false);
    } catch (error) {
      console.log(error);
    }
  };

  const handlePagination = (value) => {
    setCurrentPage(value);
  };

  const handlePagination2 = (value) => {
    setCurrentPage2(value);
  };

  const handleSearchTermChange = (newTerm) => {
    setSearchTerm(newTerm);
    setCurrentPage(1); // Imposta currentPage a 1 quando il termine di ricerca cambia
  };

  const handleSearchTermChange2 = (newTerm) => {
    setSearchTerm2(newTerm);
    setCurrentPage2(1); // Imposta currentPage a 1 quando il termine di ricerca cambia
  };

  useEffect(() => {
    getWebinars();
  }, [currentPage, searchTerm]);

  useEffect(() => {
    getWebinars2();
  }, [currentPage2, searchTerm2]);

  return (
    <>
      <div className="margin_T120 text-center ">
        <h3 className="mb-3">WEBINAR</h3>
        <input
          className="d-none"
          type="text"
          placeholder="Cerca per titolo"
          value={searchTerm}
          onChange={(e) => handleSearchTermChange(e.target.value)}
        />

        <Container fluid>
          <Row className="mt-5 d-flex flex-wrap justify-content-center">
            {webinars &&
              webinars.webinars
                ?.filter((webinar) =>
                  webinar.titolo
                    .toLowerCase()
                    .includes(searchTerm.toLowerCase())
                )
                .map((webinar) => {
                  return (
                    <Col key={webinar._id} sm={12} md={6} lg={4} xl={3}>
                      <Card className="cardCustom">
                        <Link
                          to={`/dettagliWebinar/${webinar._id}`}
                          className="clear_link_black"
                        >
                          <Card.Img
                            className="imgCard"
                            style={{ width: "18rem", height: "10rem" }}
                            variant="top"
                            src={webinar.cover}
                          />
                          <Card.Body>
                            <Card.Title>{webinar.titolo}</Card.Title>

                            {/* <Button variant="outline-danger">
                              Vai alla categoria
                            </Button> */}
                          </Card.Body>
                        </Link>
                      </Card>
                    </Col>
                  );
                })}
          </Row>
        </Container>

        <div className="mb-5">
          <ResponsivePagination
            current={currentPage}
            total={webinars && webinars.totalPages}
            onPageChange={handlePagination}
          />
        </div>
        <hr />
        <input
          className="my_input2 mt-5 mb-5"
          type="text"
          placeholder="Cerca per titolo"
          value={searchTerm2}
          onChange={(e) => handleSearchTermChange2(e.target.value)}
        />
        <Container fluid>
          <Row className="d-flex flex-wrap justify-content-center">
            {webinars2 &&
              webinars2.webinars
                ?.filter((webinar) =>
                  webinar.titolo
                    .toLowerCase()
                    .includes(searchTerm2.toLowerCase())
                )
                .map((webinar) => {
                  return (
                    <Col key={webinar._id} sm={12} md={6} lg={4} xl={3}>
                      <Card className="cardCustom">
                        <Link
                          to={`/dettagliWebinar/${webinar._id}`}
                          className="clear_link_black"
                        >
                          <Card.Img
                            className="imgCard"
                            style={{ width: "18rem", height: "10rem" }}
                            variant="top"
                            src={webinar.cover}
                          />
                          <Card.Body
                            style={{
                              height: "88px",
                            }}
                          >
                            <Card.Title>{webinar.titolo}</Card.Title>
                          </Card.Body>
                        </Link>
                      </Card>
                    </Col>
                  );
                })}
          </Row>
        </Container>
        <div className="mb-5">
          <ResponsivePagination
            current={currentPage2}
            total={webinars2 && webinars2.totalPages}
            onPageChange={handlePagination2}
          />
        </div>
      </div>
    </>
  );
};

export default WebinarNoLog;
