// import React, { useEffect, useState } from "react";
// import { Badge, Dropdown, NavDropdown, Navbar } from "react-bootstrap";
// import { Link, useNavigate } from "react-router-dom";
// //import "./navbar.css";
// import logoSY from "../../assets/LogoSY.jpg";
// //import gameM2 from "../../assets/m2playerGAME.png"
// //import postM2 from "../../assets/m2playerPOST.png"
// import { Cart2, House } from "react-bootstrap-icons";
// import useSession from "../../hooks/useSession";
// import { useCart } from "../../context/CartContext";

// const MyNav = () => {
//   const session = useSession();
//   const navigate = useNavigate();
//   const token = useCart();

//   //console.log(token);

//   //const { numeroElementiCarrello, setNumeroElementiCarrello } = useCart();
//   // const [numeroElementiCarrello, setNumeroElementiCarrello] = useState(0);

//   // // Aggiorna il numero di elementi nel carrello
//   // useEffect(() => {
//   //   // Recupera il numero di elementi nel carrello dallo stato della sessione
//   //   const numeroElementi = session?.carrello?.length || 0;
//   //   // Aggiorna lo stato locale con il nuovo numero di elementi
//   //   setNumeroElementiCarrello(numeroElementi);
//   // }, [session.carrello]);

//   const handleLogout = () => {
//     // Rimuovi il token di autenticazione
//     localStorage.removeItem("loggedInUser");
//     navigate("/");
//   };

//   // Calcola il numero di elementi nel carrello
//   const numeroElementiCarrello = session?.carrello?.length || 0;
//   //d-md-none x headerPiccolo
//   //d-none d-md-flex d-xxl-flex x headerGrande

//   return (
//     <div className="container-fluid blog-navbar fixed-top bg_nav ">
//       <div className="d-flex justify-content-between align-items-center ">
//         <div className="d-flex align-items-center">
//           <div>
//             <Navbar.Brand as={Link} to={session ? "/home" : "/"}>
//               <img className="logo_SY my-2" alt="logo" src={logoSY} />
//             </Navbar.Brand>
//           </div>

//           {/* <div className="d-flex tag-ul d-none d-md-flex d-xxl-flex">
//                         <a href="/allGame">
//                             <img className="game_post_link ms-mod-nav" alt="logo" src={gameM2} />
//                         </a>
//                         <a href="/allPost">
//                             <img className="game_post_link ms-5" alt="logo" src={postM2} />
//                         </a> */}
//           {/* <ul className="d-flex tag-ul d-none d-md-flex d-xxl-flex">
//                             <li className="mx-3"><a className="nav-a" href="/allGame">Game</a></li>
//                             <li className="mx-3 "><a className="nav-a" href="/allPost">Post</a></li>
//                         </ul>
//                     </div> */}
//         </div>
//         {/* <div>
//                     <p className="mx-2"><a className="nav-a" href="/allGame">Game</a></p>
//                 </div>
//                 <div>
//                     <p className="mx-2 "><a className="nav-a" href="/allPost">Post</a></p>
//                 </div> */}

//         <div className="d-flex align-items-center">
//           {session && (
//             <>
//               <div>
//                 <Link to={"/home"}>
//                   <House color="black" size={30} role="button" />
//                 </Link>
//                 <Link to={"/carrello"} className="position-relative">
//                   <Cart2
//                     className="me-3 ms-4"
//                     color="black"
//                     size={30}
//                     role="button"
//                   />
//                   {/* Visualizza il badge solo se ci sono elementi nel carrello */}
//                   {numeroElementiCarrello > 0 && (
//                     <Badge
//                       pill
//                       bg="danger"
//                       className="position-absolute carrello_badge translate-middle"
//                     >
//                       {numeroElementiCarrello}
//                     </Badge>
//                   )}
//                 </Link>
//               </div>
//               <div>
//                 <Dropdown className="">
//                   <Dropdown.Toggle
//                     variant="transparent"
//                     id="dropdown-basic"
//                     className="togli-bordo"
//                   >
//                     <img
//                       src={`${session.avatar}`}
//                       alt="logo avatar"
//                       className="img-avatar"
//                     />
//                   </Dropdown.Toggle>

//                   <Dropdown.Menu>
//                     <NavDropdown.Item className="selection" href="/home">
//                       Home
//                     </NavDropdown.Item>
//                     <NavDropdown.Item
//                       className="selection"
//                       href={`/dettagliAccount/${session.id}`}
//                     >
//                       Dettagli Account
//                     </NavDropdown.Item>

//                     {session.role === "admin" && (
//                       <>
//                         <NavDropdown.Item
//                           className="selection"
//                           href="/addCourse"
//                         >
//                           Aggiungi Corso
//                         </NavDropdown.Item>
//                         <NavDropdown.Item
//                           className="selection"
//                           href="/addVideo"
//                         >
//                           Aggiungi Video
//                         </NavDropdown.Item>
//                         <NavDropdown.Item
//                           className="selection"
//                           href="/addAccountGestionale"
//                         >
//                           Aggiungi Account
//                         </NavDropdown.Item>
//                         <NavDropdown.Item
//                           className="selection"
//                           href="/gestionale"
//                         >
//                           Account
//                         </NavDropdown.Item>

//                         <NavDropdown.Item
//                           className="selection"
//                           href="/gestionaleCorsi"
//                         >
//                           Corsi
//                         </NavDropdown.Item>

//                         <NavDropdown.Item
//                           className="selection"
//                           href="/gestionaleVideo"
//                         >
//                           Video
//                         </NavDropdown.Item>
//                       </>
//                     )}
//                     <NavDropdown.Divider />
//                     <NavDropdown.Item
//                       onClick={handleLogout}
//                       className="selection"
//                     >
//                       Logout
//                     </NavDropdown.Item>
//                   </Dropdown.Menu>
//                 </Dropdown>
//               </div>
//             </>
//           )}
//         </div>
//       </div>
//     </div>
//   );
// };

// export default MyNav;

import React, { useEffect, useState } from "react";
import { Badge, Dropdown, NavDropdown, Navbar } from "react-bootstrap";
import { Link, useNavigate } from "react-router-dom";
//import "./navbar.css";
// import logoSY from "../../assets/LogoSY.jpg";
import logoSY from "../../assets/LogoSYStore.png";
//import gameM2 from "../../assets/m2playerGAME.png"
//import postM2 from "../../assets/m2playerPOST.png"
import {
  Cart2,
  ChatSquareText,
  ChatSquareTextFill,
  ChatTextFill,
  House,
  PersonGear,
} from "react-bootstrap-icons";
import useSession from "../../hooks/useSession";
import { useCart } from "../../context/CartContext";
import { Divide as Hamburger } from "hamburger-react";
import AlertMessage from "../alertMessage/AlertMessage";
import { PuffLoader } from "react-spinners";
import { Button, Form, Modal } from "react-bootstrap";
import { EyeFill } from "react-bootstrap-icons";
import AxiosClient from "../../client/client";

const MyNav = () => {
  const session = useSession();
  const navigate = useNavigate();
  const token = useCart();
  const client = new AxiosClient();
  const [isOpen2, setIsOpen2] = useState(false);

  const [showSubLinksWebinar, setShowSubLinksWebinar] = useState(false);

  const handleWebinarClick = () => {
    setShowSubLinksWebinar(!showSubLinksWebinar); // Alterna la visibilità
  };

  const [showSubLinksCorsi, setShowSubLinksCorsi] = useState(false);

  const handleCorsiClick = () => {
    setShowSubLinksCorsi(!showSubLinksCorsi); // Alterna la visibilità
  };

  const [showSubLinksSoftware, setShowSubLinksSoftware] = useState(false);

  const handleSoftwareClick = () => {
    setShowSubLinksSoftware(!showSubLinksSoftware); // Alterna la visibilità
  };

  const [clicked, setClicked] = useState(false);

  const handleClick = () => {
    setClicked(!clicked);
  };

  // DROPDOWN MENU WEBINAR
  const [show, setShow] = useState(false);
  const handleMouseEnter = () => setShow(true);
  const handleMouseLeave = () => setShow(false);

  // DROPDOWN MENU CORSI
  const [show2, setShow2] = useState(false);
  const handleMouseEnter2 = () => setShow2(true);
  const handleMouseLeave2 = () => setShow2(false);

  // DROPDOWN MENU SOFTWARE
  const [show3, setShow3] = useState(false);
  const handleMouseEnter3 = () => setShow3(true);
  const handleMouseLeave3 = () => setShow3(false);

  const [showContact, setShowContact] = useState(false);
  const [problemDescription, setProblemDescription] = useState("");

  //console.log(token);

  //const { numeroElementiCarrello, setNumeroElementiCarrello } = useCart();
  // const [numeroElementiCarrello, setNumeroElementiCarrello] = useState(0);

  // // Aggiorna il numero di elementi nel carrello
  // useEffect(() => {
  //   // Recupera il numero di elementi nel carrello dallo stato della sessione
  //   const numeroElementi = session?.carrello?.length || 0;
  //   // Aggiorna lo stato locale con il nuovo numero di elementi
  //   setNumeroElementiCarrello(numeroElementi);
  // }, [session.carrello]);

  const handleLogout = () => {
    // Rimuovi il token di autenticazione
    localStorage.removeItem("loggedInUser");
    navigate("/");
  };

  // Calcola il numero di elementi nel carrello
  const numeroElementiCarrello = session?.carrello?.length || 0;
  //d-md-none x headerPiccolo
  //d-none d-md-flex d-xxl-flex x headerGrande

  ///////////////////////////////////////////////

  //<div className="d-flex tag-ul d-none d-md-flex d-xxl-flex">
  //                        <a href="/allGame">
  //                            <img className="game_post_link ms-mod-nav" alt="logo" src={gameM2} />
  //                       </a>
  //                     <a href="/allPost">
  //                       <img className="game_post_link ms-5" alt="logo" src={postM2} />
  //                 </a>
  // <ul className="d-flex tag-ul d-none d-md-flex d-xxl-flex">
  //                 <li className="mx-3"><a className="nav-a" href="/allGame">Game</a></li>
  //               <li className="mx-3 "><a className="nav-a" href="/allPost">Post</a></li>
  //         </ul>
  //   </div>

  ///////////////////////////////////////////////

  const handleLinkClick = () => {
    setIsOpen2(false);
  };

  const handleClickDoppioWebinar = () => {
    handleLinkClick();
    handleWebinarClick();
  };

  const handleClickDoppioCorsi = () => {
    handleLinkClick();
    handleCorsiClick();
  };

  ///////////////////////////////////
  const [loginData, setLoginData] = useState({});
  const [login, setLogin] = useState(null);
  const [successMessage, setSuccessMessage] = useState(null);
  const [failedMessage, setFailedMessage] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [showPassword, setShowPassword] = useState(false);

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  const openEditModal = (accountData) => {
    setShowModal(true);
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setLoginData({
      ...loginData,
      [name]: value,
    });
  };

  const onSubmit = async (e) => {
    e.preventDefault();

    setIsLoading(true);
    setTimeout(async () => {
      try {
        const response = await fetch(
          `${process.env.REACT_APP_SERVER_BASE_URL}/login`,
          {
            headers: {
              "Content-Type": "application/json",
            },
            method: "POST",
            body: JSON.stringify(loginData),
          }
        );

        const data = await response.json();

        if (data.token) {
          localStorage.setItem("loggedInUser", JSON.stringify(data.token));
        }
        setLogin(data);

        if (response.status === 200) {
          setIsLoading(false);
          setSuccessMessage("Login effettuato con successo!");
          setTimeout(() => {
            setSuccessMessage(null);
            navigate(`/home`);
            setShowModal(false);
            handleLinkClick();
          }, 3000);
        } else {
          setIsLoading(false);
          console.error("Errore nel login");
          setFailedMessage("Email o password errati!");
          setTimeout(() => {
            setFailedMessage(null);
          }, 3000);
        }

        if (response.status === 401) {
          setIsLoading(false);
          setFailedMessage("Account ancora non attivo!");
          setTimeout(() => {
            setFailedMessage(null);
          }, 3000);
        }
      } catch (e) {
        setIsLoading(false);
        console.log(e, "Errore nell'invio dei dati");
        setFailedMessage("Errore nella richiesta al server");
        setTimeout(() => {
          setFailedMessage(null);
        }, 3000);
      }
    }, 2000);
  };

  const handleClickModal = () => {
    handleLinkClick();
    setShowModal(false);
  };

  const handleCloseContact = () => {
    setShowContact(false);
    setProblemDescription("");
  };

  const handleShowContact = () => setShowContact(true);

  const inviaEmailContatto = async () => {
    try {
      const response = await client.post(`/inviaEmailContatto/${session.id}`, {
        description: problemDescription,
      });
      console.log("Email inviata:", response);
      handleCloseContact();
      setProblemDescription("");
    } catch (error) {
      console.error("Errore nell'invio dell'email:", error);
    }
  };

  return (
    <>
      {successMessage && (
        <div>
          <AlertMessage message={successMessage} success={true} />
        </div>
      )}

      {failedMessage && (
        <div>
          <AlertMessage message={failedMessage} success={false} />
        </div>
      )}

      {isLoading && (
        <div className="alert-container">
          <PuffLoader color="#ec800f" size={200} />
        </div>
      )}

      {/* SCHERMO GRANDE */}
      <div className="container-fluid blog-navbar fixed-top bg_nav ">
        <div className="d-flex justify-content-around align-items-center d-none d-lg-flex d-xxl-flex ">
          <div className="d-flex align-items-center justify-content-around">
            <div>
              <Navbar.Brand as={Link} to={session ? "/home" : "/"}>
                <img className="logo_SY " alt="logo" src={logoSY} />
              </Navbar.Brand>
            </div>
            {!session && (
              <>
                <div className="d-flex ms-5">
                  <Link
                    className="link_Nav mx-3"
                    to={"https://www.symbiotech.it/"}
                    target="_blank"
                  >
                    <p className="text_navLink">CHI SIAMO</p>
                  </Link>
                  <Link
                    className="link_Nav mx-3"
                    to={"https://www.symbiotech.it/news-autodesk/"}
                    target="_blank"
                  >
                    <p className="text_navLink">NEWS</p>
                  </Link>

                  {/* ADD QUI IL RESTO */}

                  {/* WEBINAR */}
                  <Dropdown
                    onMouseEnter={handleMouseEnter}
                    onMouseLeave={handleMouseLeave}
                    show={show}
                  >
                    <Dropdown.Toggle
                      variant="transparent"
                      id="dropdown-basic"
                      className="togli-bordo link_Nav mx-3 m-0 togli-p-m"
                    >
                      <p className="text_navLink">WEBINAR</p>
                    </Dropdown.Toggle>

                    <Dropdown.Menu>
                      <Dropdown.Item
                        href="https://www.symbiotech.it/webinar/"
                        target="_blank"
                      >
                        IN PROGRAMMA
                      </Dropdown.Item>
                      <Dropdown.Item href={"/webinarList"}>
                        DISPONIBILI
                      </Dropdown.Item>
                    </Dropdown.Menu>
                  </Dropdown>

                  {/* CORSI */}
                  <Dropdown
                    onMouseEnter={handleMouseEnter2}
                    onMouseLeave={handleMouseLeave2}
                    show={show2}
                  >
                    <Dropdown.Toggle
                      variant="transparent"
                      id="dropdown-basic"
                      className="togli-bordo link_Nav mx-3 m-0 togli-p-m"
                    >
                      <p className="text_navLink">CORSI</p>
                    </Dropdown.Toggle>

                    <Dropdown.Menu>
                      <Dropdown.Item
                        href="https://www.symbiotech.it/corsi-autodesk/"
                        target="_blank"
                      >
                        CALENDARIO
                      </Dropdown.Item>
                      <Dropdown.Item href="/corsiList">
                        DISPONIBILI
                      </Dropdown.Item>
                    </Dropdown.Menu>
                  </Dropdown>

                  {/* SOFTWARE */}
                  <Dropdown
                    onMouseEnter={handleMouseEnter3}
                    onMouseLeave={handleMouseLeave3}
                    show={show3}
                  >
                    <Dropdown.Toggle
                      variant="transparent"
                      id="dropdown-basic"
                      className="togli-bordo link_Nav mx-3 m-0 togli-p-m"
                    >
                      <p className="text_navLink">SOFTWARE</p>
                    </Dropdown.Toggle>

                    <Dropdown.Menu>
                      <Dropdown.Item
                        href="https://www.symbiotech.it/symbiotech-app-autodesk/"
                        target="_blank"
                      >
                        SYMBIOTECH
                      </Dropdown.Item>
                      <Dropdown.Item
                        href="https://www.symbiotech.it/software-autodesk/"
                        target="_blank"
                      >
                        AUTODESK
                      </Dropdown.Item>
                    </Dropdown.Menu>
                  </Dropdown>

                  {/* REWARDS */}
                  <Link
                    className="link_Nav mx-3"
                    to={`https://www.symbiotech.it/symbiotech-rewards/`}
                    target="_blank"
                  >
                    <p className="text_navLink">REWARDS</p>
                  </Link>

                  {/* CONTATTI */}
                  <Link
                    className="link_Nav mx-3"
                    to={"https://www.symbiotech.it/#contatti"}
                    target="_blank"
                  >
                    <p className="text_navLink">CONTATTI</p>
                  </Link>
                </div>
              </>
            )}
          </div>

          {session && (
            <>
              <div className="d-flex">
                <Link
                  className="link_Nav mx-3"
                  to={"https://www.symbiotech.it/news-autodesk/"}
                  target="_blank"
                >
                  <p className="text_navLink">NEWS</p>
                </Link>

                <Dropdown
                  onMouseEnter={handleMouseEnter}
                  onMouseLeave={handleMouseLeave}
                  show={show}
                >
                  <Dropdown.Toggle
                    variant="transparent"
                    id="dropdown-basic"
                    className="togli-bordo link_Nav mx-3 m-0 togli-p-m"
                  >
                    <p className="text_navLink">WEBINAR</p>
                  </Dropdown.Toggle>

                  <Dropdown.Menu>
                    <Dropdown.Item
                      href="https://www.symbiotech.it/webinar/"
                      target="_blank"
                    >
                      IN PROGRAMMA
                    </Dropdown.Item>
                    <Dropdown.Item href={"/webinar"}>DISPONIBILI</Dropdown.Item>
                    {/* <Dropdown.Item href={"/workInProgressWebinar"}>
                      DISPONIBILI
                    </Dropdown.Item> */}
                  </Dropdown.Menu>
                </Dropdown>

                <Dropdown
                  onMouseEnter={handleMouseEnter2}
                  onMouseLeave={handleMouseLeave2}
                  show={show2}
                >
                  <Dropdown.Toggle
                    variant="transparent"
                    id="dropdown-basic"
                    className="togli-bordo link_Nav mx-3 m-0 togli-p-m"
                  >
                    <p className="text_navLink">CORSI</p>
                  </Dropdown.Toggle>

                  <Dropdown.Menu>
                    <Dropdown.Item
                      href="https://www.symbiotech.it/corsi-autodesk/"
                      target="_blank"
                    >
                      CALENDARIO
                    </Dropdown.Item>
                    {/* <Dropdown.Item href="/corsi">DISPONIBILI</Dropdown.Item> */}
                    <Dropdown.Item href="/corsiList">DISPONIBILI</Dropdown.Item>
                    {/* <Dropdown.Item href="/workInProgressCorsi">
                      DISPONIBILI
                    </Dropdown.Item> */}
                  </Dropdown.Menu>
                </Dropdown>

                {/* <Link
                  className="link_Nav mx-3"
                  to={"https://www.symbiotech.it/"}
                >
                  <p className="text_navLink">SOFTWARE</p>
                </Link> */}
                <Dropdown
                  onMouseEnter={handleMouseEnter3}
                  onMouseLeave={handleMouseLeave3}
                  show={show3}
                >
                  <Dropdown.Toggle
                    variant="transparent"
                    id="dropdown-basic"
                    className="togli-bordo link_Nav mx-3 m-0 togli-p-m"
                  >
                    <p className="text_navLink">SOFTWARE</p>
                  </Dropdown.Toggle>

                  <Dropdown.Menu>
                    <Dropdown.Item
                      href="https://www.symbiotech.it/symbiotech-app-autodesk/"
                      target="_blank"
                    >
                      SYMBIOTECH
                    </Dropdown.Item>
                    <Dropdown.Item
                      href="https://www.symbiotech.it/software-autodesk/"
                      target="_blank"
                    >
                      AUTODESK
                    </Dropdown.Item>
                  </Dropdown.Menu>
                </Dropdown>
                <Link
                  className="link_Nav mx-3"
                  to={
                    session.role === "azienda"
                      ? `/aziendaInfo/${session.id}`
                      : `https://www.symbiotech.it/symbiotech-rewards/`
                  }
                  target={session.role === "azienda" ? "" : "_blank"}
                >
                  <p className="text_navLink">REWARDS</p>
                </Link>
                <Link
                  className="link_Nav mx-3"
                  to={"https://www.symbiotech.it/#contatti"}
                  target="_blank"
                >
                  <p className="text_navLink">CONTATTI</p>
                </Link>
              </div>

              <div className="d-flex colonnaDXnav justify-content-center align-items-center">
                {/* CARRELLO ------------------------------------------ */}
                {/* <div>
                  <Link to={"/carrello"} className="position-relative">
                    <Cart2
                      className="me-3 ms-4"
                      color="black"
                      size={30}
                      role="button"
                    />
                    {numeroElementiCarrello > 0 && (
                      <Badge
                        pill
                        bg="danger"
                        className="position-absolute carrello_badge translate-middle"
                      >
                        {numeroElementiCarrello}
                      </Badge>
                    )}
                  </Link>
                </div> */}
                {/* ------------------------------------------------------------ */}
                <div>
                  <ChatTextFill
                    color="black"
                    size={30}
                    role="button"
                    onClick={handleShowContact}
                  />
                </div>
                <div className="">
                  <Dropdown className="">
                    <Dropdown.Toggle
                      variant="transparent"
                      id="dropdown-basic"
                      className="togli-bordo"
                    >
                      <PersonGear color="black" size={36} />
                    </Dropdown.Toggle>

                    <Dropdown.Menu>
                      <NavDropdown.Item className="selection" href="/home">
                        Home
                      </NavDropdown.Item>
                      <NavDropdown.Item
                        className="selection"
                        href={`/dettagliAccount/${session.id}`}
                      >
                        Area Personale
                      </NavDropdown.Item>

                      {session.role === "azienda" && (
                        <>
                          <NavDropdown.Item
                            className="selection"
                            href={`/aziendaInfo/${session.id}`}
                          >
                            SymbioTech Rewards
                          </NavDropdown.Item>
                        </>
                      )}

                      {session.role === "admin" && (
                        <>
                          <NavDropdown.Item
                            className="selection"
                            href="/versionSoftware"
                          >
                            Vers. Software SYM
                          </NavDropdown.Item>
                          <NavDropdown.Item
                            className="selection"
                            href="/addCourse"
                          >
                            Aggiungi Corso
                          </NavDropdown.Item>

                          <NavDropdown.Item
                            className="selection"
                            href="/addWebinar"
                          >
                            Aggiungi Webinar
                          </NavDropdown.Item>

                          <NavDropdown.Item
                            className="selection"
                            href="/addVideo"
                          >
                            Aggiungi Video
                          </NavDropdown.Item>

                          <NavDropdown.Item
                            className="selection"
                            href="/addAccountGestionale"
                          >
                            Aggiungi Account
                          </NavDropdown.Item>

                          <NavDropdown.Item
                            className="selection"
                            href="/gestionale"
                          >
                            Account
                          </NavDropdown.Item>

                          <NavDropdown.Item
                            className="selection"
                            href="/gestionaleCorsi"
                          >
                            Corsi
                          </NavDropdown.Item>

                          <NavDropdown.Item
                            className="selection"
                            href="/gestionaleWebinar"
                          >
                            Webinar
                          </NavDropdown.Item>

                          <NavDropdown.Item
                            className="selection"
                            href="/gestionaleVideo"
                          >
                            Video
                          </NavDropdown.Item>
                        </>
                      )}

                      <NavDropdown.Divider />
                      <NavDropdown.Item
                        onClick={handleLogout}
                        className="selection"
                      >
                        Logout
                      </NavDropdown.Item>
                    </Dropdown.Menu>
                  </Dropdown>
                </div>
              </div>
            </>
          )}
        </div>

        {/* SCHERMO PICCOLO */}

        <div className="d-flex justify-content-around align-items-center d-lg-none">
          <div className="d-flex align-items-center">
            <div>
              <Navbar.Brand as={Link} to={session ? "/home" : "/"}>
                <img className="logo_SY " alt="logo" src={logoSY} />
              </Navbar.Brand>
            </div>
          </div>

          <div className="d-flex hamburger-container-2">
            <Hamburger
              size={35}
              toggled={isOpen2}
              toggle={setIsOpen2}
              color={"black"}
            />
            <div className={`menu-2 ${isOpen2 ? "open2" : ""} `}>
              {/* CORPO HAMBURGER */}
              {!session && (
                <>
                  <Link
                    className="link_Nav_Mobile mx-3 mt-3"
                    to={"https://www.symbiotech.it"}
                    target="_blank"
                  >
                    <p className="text_navLink_mobile">SCOPRI SYMBIOTECH</p>
                  </Link>
                  <Link
                    className="link_Nav_Mobile mx-3"
                    to={"https://www.symbiotech.it/news-autodesk/"}
                    target="_blank"
                  >
                    <p className="text_navLink_mobile">NEWS</p>
                  </Link>

                  {/* webinar */}
                  <Link
                    className="link_Nav_Mobile mx-3"
                    onClick={handleWebinarClick}
                  >
                    <p className="text_navLink_mobile">WEBINAR</p>
                  </Link>
                  {showSubLinksWebinar && (
                    <>
                      <Link
                        className="link_Nav_Mobile mx-3 ms-4"
                        onClick={handleClickDoppioWebinar}
                        // to={"/workInProgressWebinar"}
                        to={"/webinarList"}
                      >
                        <p className="text_sottoLink_mobile">DISPONIBILI</p>
                      </Link>
                      <Link
                        className="link_Nav_Mobile mx-3 ms-4 mt-0"
                        to={"https://www.symbiotech.it/webinar/"}
                        target="_blank"
                      >
                        <p className="text_sottoLink_mobile">IN PROGRAMMA</p>
                      </Link>
                      <hr className="mt-0" />{" "}
                    </>
                  )}

                  {/* corsi */}
                  <Link
                    className="link_Nav_Mobile mx-3 "
                    onClick={handleCorsiClick}
                  >
                    <p className="text_navLink_mobile">CORSI</p>
                  </Link>
                  {showSubLinksCorsi && (
                    <>
                      <Link
                        className="link_Nav_Mobile mx-3 ms-4"
                        onClick={handleClickDoppioCorsi}
                        to={"/corsiList"}
                      >
                        <p className="text_sottoLink_mobile">DISPONIBILI</p>
                      </Link>
                      <Link
                        className="link_Nav_Mobile mx-3 ms-4 mt-0"
                        to={"https://www.symbiotech.it/corsi-autodesk/"}
                        target="_blank"
                      >
                        <p className="text_sottoLink_mobile">CALENDARIO</p>
                      </Link>
                      <hr className="mt-0" />{" "}
                    </>
                  )}

                  {/* software */}
                  <Link
                    className="link_Nav_Mobile mx-3"
                    onClick={handleSoftwareClick}
                  >
                    <p className="text_navLink_mobile">SOFTWARE</p>
                  </Link>
                  {showSubLinksSoftware && (
                    <>
                      <Link
                        className="link_Nav_Mobile mx-3 ms-4"
                        to={"https://www.symbiotech.it/software-autodesk/"}
                        target="_blank"
                      >
                        <p className="text_sottoLink_mobile">AUTODESK</p>
                      </Link>
                      <Link
                        className="link_Nav_Mobile mx-3 ms-4 mt-0"
                        to={
                          "https://www.symbiotech.it/symbiotech-app-autodesk/"
                        }
                        target="_blank"
                      >
                        <p className="text_sottoLink_mobile">SYMBIOTECH</p>
                      </Link>
                      <hr className="mt-0" />{" "}
                    </>
                  )}

                  <Link
                    className="link_Nav_Mobile mx-3"
                    to={`https://www.symbiotech.it/symbiotech-rewards/`}
                    target="_blank"
                  >
                    <p className="text_navLink_mobile">REWARDS</p>
                  </Link>

                  <Link
                    className="link_Nav_Mobile mx-3"
                    to={"https://www.symbiotech.it/#contatti"}
                    target="_blank"
                  >
                    <p className="text_navLink_mobile">CONTATTI</p>
                  </Link>
                  <hr />
                  <Link
                    className="link_Nav_Mobile mx-3"
                    onClick={() => openEditModal()}
                  >
                    <p className="text_navLink_mobile">LOGIN</p>
                  </Link>
                </>
              )}

              {/* LOGGATO */}
              {session && (
                <>
                  <Link
                    className="link_Nav_Mobile mx-3 mt-3"
                    onClick={handleLinkClick}
                    to={"https://www.symbiotech.it/news-autodesk/"}
                    target="_blank"
                  >
                    <p className="text_navLink_mobile">NEWS</p>
                  </Link>

                  {/* webinar */}
                  <Link
                    className="link_Nav_Mobile mx-3"
                    onClick={handleWebinarClick}
                  >
                    <p className="text_navLink_mobile">WEBINAR</p>
                  </Link>
                  {showSubLinksWebinar && (
                    <>
                      <Link
                        className="link_Nav_Mobile mx-3 ms-4"
                        onClick={handleClickDoppioWebinar}
                        // to={"/workInProgressWebinar"}
                        to={"/webinar"}
                      >
                        <p className="text_sottoLink_mobile">DISPONIBILI</p>
                      </Link>
                      <Link
                        className="link_Nav_Mobile mx-3 ms-4 mt-0"
                        to={"https://www.symbiotech.it/webinar/"}
                        target="_blank"
                      >
                        <p className="text_sottoLink_mobile">IN PROGRAMMA</p>
                      </Link>
                      <hr className="mt-0" />{" "}
                    </>
                  )}

                  {/* corsi */}
                  <Link
                    className="link_Nav_Mobile mx-3 "
                    onClick={handleCorsiClick}
                  >
                    <p className="text_navLink_mobile">CORSI</p>
                  </Link>
                  {showSubLinksCorsi && (
                    <>
                      <Link
                        className="link_Nav_Mobile mx-3 ms-4"
                        onClick={handleClickDoppioCorsi}
                        to={"/corsiList"}
                      >
                        <p className="text_sottoLink_mobile">DISPONIBILI</p>
                      </Link>
                      <Link
                        className="link_Nav_Mobile mx-3 ms-4 mt-0"
                        to={"https://www.symbiotech.it/corsi-autodesk/"}
                        target="_blank"
                      >
                        <p className="text_sottoLink_mobile">CALENDARIO</p>
                      </Link>
                      <hr className="mt-0" />{" "}
                    </>
                  )}

                  {/* software */}
                  <Link
                    className="link_Nav_Mobile mx-3"
                    onClick={handleSoftwareClick}
                  >
                    <p className="text_navLink_mobile">SOFTWARE</p>
                  </Link>
                  {showSubLinksSoftware && (
                    <>
                      <Link
                        className="link_Nav_Mobile mx-3 ms-4"
                        to={"https://www.symbiotech.it/software-autodesk/"}
                        target="_blank"
                      >
                        <p className="text_sottoLink_mobile">AUTODESK</p>
                      </Link>
                      <Link
                        className="link_Nav_Mobile mx-3 ms-4 mt-0"
                        to={
                          "https://www.symbiotech.it/symbiotech-app-autodesk/"
                        }
                        target="_blank"
                      >
                        <p className="text_sottoLink_mobile">SYMBIOTECH</p>
                      </Link>
                      <hr className="mt-0" />{" "}
                    </>
                  )}

                  <Link
                    className="link_Nav_Mobile mx-3"
                    onClick={handleLinkClick}
                    to={
                      session.role === "azienda"
                        ? `/aziendaInfo/${session.id}`
                        : `https://www.symbiotech.it/symbiotech-rewards/`
                    }
                    target={session.role === "azienda" ? "" : "_blank"}
                  >
                    <p className="text_navLink_mobile">REWARDS</p>
                  </Link>

                  <Link
                    className="link_Nav_Mobile mx-3"
                    onClick={handleLinkClick}
                    to={"https://www.symbiotech.it/#contatti"}
                    target="_blank"
                  >
                    <p className="text_navLink_mobile">CONTATTI</p>
                  </Link>

                  <hr />
                  {/* CARRELLO -------------------------------- */}
                  {/* <Link
                    to={"/carrello"}
                    onClick={handleLinkClick}
                    className="position-relative link_Nav_Mobile mx-3"
                  >
                    <p className="text_navLink_mobile">CARRELLO</p>
                    {numeroElementiCarrello > 0 && (
                      <Badge
                        pill
                        bg="danger"
                        className="position-absolute carrello_badge2 translate-middle"
                      >
                        {numeroElementiCarrello}
                      </Badge>
                    )}
                  </Link> */}
                  {/* -------------------------------------------------- */}

                  <Link
                    className="link_Nav_Mobile mx-3 "
                    to={`/dettagliAccount/${session.id}`}
                    onClick={handleLinkClick}
                  >
                    <p className="text_navLink_mobile">AREA PERSONALE</p>
                  </Link>

                  <Link
                    className="link_Nav_Mobile mx-3 "
                    onClick={handleShowContact}
                  >
                    <p className="text_navLink_mobile">INVIA EMAIL</p>
                  </Link>

                  <hr />

                  <p
                    onClick={() => {
                      handleLogout();
                      handleLinkClick();
                    }}
                    className="link_Nav_Mobile mx-3 text_navLink_mobile"
                  >
                    LOGOUT
                  </p>
                </>
              )}
            </div>
          </div>
        </div>
      </div>

      {/* MODAL */}
      <Modal show={showModal} onHide={() => setShowModal(false)}>
        <Modal.Header closeButton>
          <Modal.Title>Login</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form onSubmit={onSubmit}>
            <Form.Group className="mt-3">
              <Form.Label className="ms-1 fw-bold">Email</Form.Label>
              <Form.Control
                type="text"
                size="lg"
                name="email"
                placeholder="Email"
                onChange={handleInputChange}
                required
              />
            </Form.Group>

            <Form.Group className="mt-3">
              <div className="d-flex justify-content-between mb-1">
                <Form.Label className="ms-1 fw-bold">Password</Form.Label>
                <EyeFill
                  className="ms-2"
                  role="button"
                  color="black"
                  size={30}
                  onClick={togglePasswordVisibility}
                />
              </div>
              <Form.Control
                className="custom-input"
                type={showPassword ? "text" : "password"}
                size="lg"
                name="password"
                placeholder="Password"
                onChange={handleInputChange}
                required
              />
            </Form.Group>

            <Form.Group className="d-flex flex-column my-4 justify-content-center">
              <Button
                className="my-3 text-center prova fw-bold text-black"
                type="submit"
                size="lg"
              >
                Login
              </Button>
              <Link
                to="/AddAccount"
                className="mt-5 text-black justify-content-center fw-bold"
                onClick={handleClickModal}
              >
                Non sei ancora registato? Registrati
              </Link>
              <Link
                to="/PassDimenticata"
                className="text-black justify-content-center fw-bold"
                onClick={handleClickModal}
              >
                Password dimenticata?
              </Link>
            </Form.Group>
          </Form>
        </Modal.Body>
      </Modal>

      {/* SECONDO MODAL per Contact*/}
      <Modal show={showContact} onHide={handleCloseContact}>
        <Modal.Header closeButton>
          <Modal.Title>Invia email</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form.Group controlId="formProblema">
            <Form.Label>
              Descrivi il tuo problema o inserisci la tua richiesta
            </Form.Label>
            <Form.Control
              as="textarea"
              rows={3}
              value={problemDescription}
              onChange={(e) => setProblemDescription(e.target.value)}
            />
          </Form.Group>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleCloseContact}>
            Chiudi
          </Button>
          <Button variant="primary" onClick={inviaEmailContatto}>
            Invia
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default MyNav;

{
  /* <Dropdown>
      <Dropdown.Toggle variant="success" id="dropdown-basic">
        Dropdown Button
      </Dropdown.Toggle>

      <Dropdown.Menu>
        <Dropdown.Item href="#/action-1">Action</Dropdown.Item>
        <Dropdown.Item href="#/action-2">Another action</Dropdown.Item>
        <Dropdown.Item href="#/action-3">Something else</Dropdown.Item>
      </Dropdown.Menu>
    </Dropdown> */
}
