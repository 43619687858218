import React, { useState } from "react";
import { Routes, Route } from "react-router-dom";
import SidebarWeb from "../components/sidebarWeb/SidebarWeb";
import NodeViewWeb from "../components/nodeViewWeb/NodeViewWeb";
import { Divide as Hamburger } from "hamburger-react";

const NodeLayoutWeb = () => {
  const [isOpen, setIsOpen] = useState(false);
  return (
    <div className="d-flex hamburger-container-1">
      <Hamburger
        size={35}
        toggled={isOpen}
        toggle={setIsOpen}
        color={`${isOpen ? "white" : "black"} `}
      />
      <div className={`menuSidebar ${isOpen ? "open1" : ""} `}>
        <SidebarWeb />
      </div>

      <div className="container-fluid p-0 m-0">
        <Routes>
          <Route path="/webinar/:id" element={<NodeViewWeb />} />
        </Routes>

        {/* <Route path="/corsi/view/:id" element={<NodeView />} /> */}
      </div>
    </div>
  );
};

export default NodeLayoutWeb;
