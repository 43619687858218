// import React, { useEffect, useState } from "react";
// import AxiosClient from "../client/client";
// import { useParams } from "react-router-dom";
// import DOMPurify from "dompurify";
// import useSession from "../hooks/useSession";
// import { useNavigate } from "react-router-dom";
// import { nanoid } from "nanoid";
// import OverlayTrigger from "react-bootstrap/OverlayTrigger";
// import Tooltip from "react-bootstrap/Tooltip";
// import { Button, Form, Modal } from "react-bootstrap";
// import { PuffLoader } from "react-spinners";

// const CorsoNoLogID = () => {
//   const { id } = useParams();
//   const navigate = useNavigate();
//   const session = useSession();
//   const client = new AxiosClient();

//   const [content, setContent] = useState([]);
//   const [idRamo1, setIdRamo1] = useState([]);
//   const [idRamo2, setIdRamo2] = useState([]);
//   const [idRamo3, setIdRamo3] = useState([]);
//   const [idRamo4, setIdRamo4] = useState([]);
//   const [sottoCorsi, setSottoCorsi] = useState([]);
//   const [isLoading, setIsLoading] = useState(true);

//   const getCont = async () => {
//     try {
//       const response = await client.get(`/corso/${id}`);
//       setContent(response);
//     } catch (error) {
//       // Gestisci l'errore qui, ad esempio reindirizzando alla pagina "Not Found"
//       //console.error("Errore nella richiesta GET:", error);
//       if (error.response && error.response.status === 500) {
//         navigate("/pagina-non-trovata"); // Reindirizza alla pagina "Not Found"
//       } else {
//         // Altrimenti, gestisci l'errore in base alle tue esigenze
//         // Potresti mostrare un messaggio all'utente, per esempio
//         console.error("Errore sconosciuto:", error);
//         // Oppure potresti fare altro
//       }
//     }
//   };

//   useEffect(() => {
//     getCont();
//   }, [id]);

//   const ramo1 = content.course?.ramo1;
//   const ramo2 = content.course?.ramo2;
//   const ramo3 = content.course?.ramo3;
//   const ramo4 = content.course?.ramo4;

//   const getIdRamo = async (ramo, setIdRamo) => {
//     if (ramo) {
//       const response = await client.get(`/corsoByRamo/${ramo}`);
//       setIdRamo(response);
//     }
//   };

//   useEffect(() => {
//     getIdRamo(ramo1, setIdRamo1);
//   }, [ramo1]);

//   useEffect(() => {
//     getIdRamo(ramo2, setIdRamo2);
//   }, [ramo2]);

//   useEffect(() => {
//     getIdRamo(ramo3, setIdRamo3);
//   }, [ramo3]);

//   useEffect(() => {
//     getIdRamo(ramo4, setIdRamo4);
//   }, [ramo4]);

//   const sanitizedHTML = DOMPurify.sanitize(content.course?.descrizione);

//   const handleRamo0Click = () => {
//     // Naviga alla destinazione con l'id del corso
//     navigate(`/corsiList`);
//   };

//   const handleRamo1Click = () => {
//     // Naviga alla destinazione con l'id del corso
//     navigate(`/dettagliCorso/${idRamo1.courseId}`);
//   };

//   const handleRamo2Click = () => {
//     // Naviga alla destinazione con l'id del corso
//     navigate(`/dettagliCorso/${idRamo2.courseId}`);
//   };

//   const handleRamo3Click = () => {
//     // Naviga alla destinazione con l'id del corso
//     navigate(`/dettagliCorso/${idRamo3.courseId}`);
//   };

//   let numeroRamo = "2";
//   if (!content.course?.ramo2) {
//     numeroRamo = "2";
//   } else if (!content.course?.ramo3) {
//     numeroRamo = "3";
//   } else if (!content.course?.ramo4) {
//     numeroRamo = "4";
//   }

//   //get ramo
//   const getRamo = async () => {
//     try {
//       const response = await client.get(
//         `/courses/ramo${numeroRamo}/${content.course?.titolo}`
//       );
//       setSottoCorsi(response);
//       setIsLoading(false);
//     } catch (e) {
//       console.log(e);
//     }
//   };

//   useEffect(() => {
//     getRamo();
//   }, [numeroRamo, content.course?.titolo]);

//   //LOGICA INVIO RICHIESTA CORSI LIVE

//   const inviaEmailCorsoLiveUtente = async () => {
//     try {
//       const response = await client.get(
//         `/inviaEmailCorsoLiveUtente/${session.id}/${content.course?._id}`
//       );
//     } catch (error) {
//       console.log(error);
//     }
//   };
//   const inviaEmailCorsoLiveSYM = async () => {
//     try {
//       const response = await client.get(
//         `/inviaEmailCorsoLiveSYM/${session.id}/${content.course?._id}`
//       );
//     } catch (error) {
//       console.log(error);
//     }
//   };

//   function InviaEmail() {
//     const confirmInviaEmail = window.confirm(
//       "Una volta inoltrata la tua richiesta, riceverai immediatamente una conferma di ricezione da SymbioTech Store. Successivamente riceverai un'offerta personalizzata, non vincolante, ad un prezzo promozionale dedicato a te, confermare?"
//     );

//     if (confirmInviaEmail) {
//       inviaEmailCorsoLiveSYM();
//       inviaEmailCorsoLiveUtente();
//     }
//   }
//   ///////////////

//   if (isLoading) {
//     return (
//       <div className="alert-container">
//         <PuffLoader color="#ec800f" size={200} />
//       </div>
//     ); // Mostra un messaggio di caricamento
//   }

//   return (
//     <div className="margin_T100">
//       {/* /////////////////////////////////////////////////  SCHERMO GRANDE*/}
//       <div className="d-flex flex-wrap align-items-center justify-content-between mb-2 d-none d-md-flex d-xxl-flex">
//         <div className="d-flex align-items-center font_percorso ms-3">
//           <div
//             className="puntatore text_percorso text_m1"
//             onClick={handleRamo0Click}
//           >
//             Corsi
//           </div>
//           <span className="text_slash mb-1 ms-1">/</span>

//           <div
//             className={`${
//               content.course?.ramo2 ? "puntatore" : ""
//             } text_percorso text_m1`}
//             onClick={handleRamo1Click}
//           >
//             {content.course?.ramo1}
//           </div>
//           {content.course?.ramo2 && (
//             <div className=" d-flex align-items-center text_percorso">
//               <span className="text_slash mb-1 ms-1">/</span>
//               <div
//                 className={content.course?.ramo3 ? "puntatore" : ""}
//                 onClick={handleRamo2Click}
//               >
//                 {content.course?.ramo2}
//               </div>
//               {content.course?.ramo3 && (
//                 <div className="d-flex align-items-center">
//                   <span className="text_slash mb-1 ms-1">/</span>
//                   <div
//                     className={content.course?.ramo4 ? "puntatore" : ""}
//                     onClick={handleRamo3Click}
//                   >
//                     {content.course?.ramo3}
//                   </div>
//                   {content.course?.ramo4 && (
//                     <div className="d-flex align-items-center text_percorso">
//                       <span className="text_slash mb-1 ms-1">/</span>
//                       {content.course?.ramo4}
//                     </div>
//                   )}
//                 </div>
//               )}
//             </div>
//           )}
//         </div>
//       </div>
//       {/* ///////////////////////////////////////////////// SCHERMO PICCOLO */}
//       <div className="d-flex flex-column  align-items-center justify-content-between d-md-none ">
//         <div className="d-flex align-items-center font_percorso  d-none d-sm-flex d-md-flex">
//           <div
//             className={`${
//               content.course?.ramo2 ? "puntatore" : ""
//             } text_percorso text_m1`}
//             onClick={handleRamo1Click}
//           >
//             {content.course?.ramo1}
//           </div>
//           {content.course?.ramo2 && (
//             <div className=" d-flex align-items-center text_percorso">
//               <span className="text_slash mb-1 ms-1">/</span>
//               <div
//                 className={content.course?.ramo3 ? "puntatore" : ""}
//                 onClick={handleRamo2Click}
//               >
//                 {content.course?.ramo2}
//               </div>
//               {content.course?.ramo3 && (
//                 <div className="d-flex align-items-center">
//                   <span className="text_slash mb-1 ms-1">/</span>
//                   <div
//                     className={content.course?.ramo4 ? "puntatore" : ""}
//                     onClick={handleRamo3Click}
//                   >
//                     {content.course?.ramo3}
//                   </div>
//                   {content.course?.ramo4 && (
//                     <div className="d-flex align-items-center text_percorso">
//                       <span className="text_slash mb-1 ms-1">/</span>
//                       {content.course?.ramo4}
//                     </div>
//                   )}
//                 </div>
//               )}
//             </div>
//           )}
//         </div>
//       </div>

//       {/* ///////////////////////////////////////////////// */}
//       <div className="text-center">
//         <img
//           className="imgBox-cover-home mb-3 mt-1"
//           src={content.course?.cover}
//           alt="cover"
//         />
//         <h1>{content.course?.titolo}</h1>
//         <div>
//           <div dangerouslySetInnerHTML={{ __html: sanitizedHTML }} />
//         </div>
//         <hr className="mt-5 mb-3" />

//         {sottoCorsi &&
//           isLoading === false &&
//           sottoCorsi.course &&
//           sottoCorsi.course.length > 0 && <h5>Sotto corsi:</h5>}

//         {/* --------------------------------- */}

//         {sottoCorsi &&
//           isLoading === false &&
//           sottoCorsi.course?.map((course) => {
//             const renderTooltip = (props) => (
//               <Tooltip id="button-tooltip" {...props}>
//                 {course.titolo}
//               </Tooltip>
//             );
//             return (
//               <div key={nanoid()}>
//                 {/* ---------------------------------------------- SCHERMO GRANDE */}
//                 <div
//                   key={nanoid()}
//                   className="cta2  align-items-center mb-4 d-none d-md-flex d-xxl-flex"
//                 >
//                   <div className="cta2_asd d-flex align-items-center ">
//                     <a
//                       href={`/dettagliCorso/${course._id}`}
//                       className="clear_link_black"
//                     >
//                       <img src={course.cover} alt="cover" />
//                     </a>
//                   </div>
//                   <div className="cta2DivEllipsis ">
//                     <a
//                       href={`/dettagliCorso/${course._id}`}
//                       className="clear_link_black"
//                     >
//                       <OverlayTrigger
//                         placement="top"
//                         delay={{ show: 300, hide: 100 }}
//                         overlay={renderTooltip}
//                       >
//                         <p className="ctaEllisse">{course.titolo}</p>
//                       </OverlayTrigger>
//                     </a>
//                   </div>
//                 </div>

//                 {/* ---------------------------------------------- SCHERMO PICCOLO */}
//                 <div
//                   key={nanoid()}
//                   className="cta2_mobile justify-content-center align-items-center justify-content-between mb-4 d-md-none"
//                 >
//                   <div className="cta2_asd d-flex align-items-center ">
//                     <a
//                       href={`/dettagliCorso/${course._id}`}
//                       className="clear_link_black"
//                     >
//                       <img src={course.cover} alt="cover" />
//                     </a>
//                   </div>
//                   <div className="cta2DivEllipsis ">
//                     <a
//                       href={`/dettagliCorso/${course._id}`}
//                       className="clear_link_black"
//                     >
//                       <OverlayTrigger
//                         placement="top"
//                         delay={{ show: 300, hide: 100 }}
//                         overlay={renderTooltip}
//                       >
//                         <p className="ctaEllisse">{course.titolo}</p>
//                       </OverlayTrigger>
//                     </a>
//                   </div>
//                   <div className="cta2_div"></div>
//                 </div>
//               </div>
//             );
//           })}
//         {/* NUOVE AGGIUNTE POST DEPLOY */}
//         {session && (
//           <div className="my-5">
//             <h1>IMMAGINE BANNER PER RICHIEDERE IL CORSO LIVE</h1>
//             <Button onClick={() => InviaEmail()}>
//               Invia da qui la tua richiesta
//             </Button>
//           </div>
//         )}
//       </div>
//     </div>
//   );
// };

// export default CorsoNoLogID;

import React, { useEffect, useState } from "react";
import AxiosClient from "../client/client";
import { useParams } from "react-router-dom";
import DOMPurify from "dompurify";
import useSession from "../hooks/useSession";
import { useNavigate } from "react-router-dom";
import { nanoid } from "nanoid";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Tooltip from "react-bootstrap/Tooltip";
import { Button, Container, Form, Modal } from "react-bootstrap";
import { PuffLoader } from "react-spinners";
import BannerCorsiLive from "../assets/Banner corsi live.png";
import BannerPubblCashback from "../assets/BannerPubblCashback.png";

const CorsoNoLogID = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const session = useSession();
  const client = new AxiosClient();

  const [content, setContent] = useState([]);
  const [idRamo1, setIdRamo1] = useState([]);
  const [idRamo2, setIdRamo2] = useState([]);
  const [idRamo3, setIdRamo3] = useState([]);
  const [idRamo4, setIdRamo4] = useState([]);
  const [sottoCorsi, setSottoCorsi] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  const getCont = async () => {
    try {
      const response = await client.get(`/corso/${id}`);
      setContent(response);
    } catch (error) {
      // Gestisci l'errore qui, ad esempio reindirizzando alla pagina "Not Found"
      //console.error("Errore nella richiesta GET:", error);
      if (error.response && error.response.status === 500) {
        navigate("/pagina-non-trovata"); // Reindirizza alla pagina "Not Found"
      } else {
        // Altrimenti, gestisci l'errore in base alle tue esigenze
        // Potresti mostrare un messaggio all'utente, per esempio
        console.error("Errore sconosciuto:", error);
        // Oppure potresti fare altro
      }
    }
  };

  useEffect(() => {
    getCont();
  }, [id]);

  const ramo1 = content.course?.ramo1;
  const ramo2 = content.course?.ramo2;
  const ramo3 = content.course?.ramo3;
  const ramo4 = content.course?.ramo4;

  const getIdRamo = async (ramo, setIdRamo) => {
    if (ramo) {
      const response = await client.get(`/corsoByRamo/${ramo}`);
      setIdRamo(response);
    }
  };

  useEffect(() => {
    getIdRamo(ramo1, setIdRamo1);
  }, [ramo1]);

  useEffect(() => {
    getIdRamo(ramo2, setIdRamo2);
  }, [ramo2]);

  useEffect(() => {
    getIdRamo(ramo3, setIdRamo3);
  }, [ramo3]);

  useEffect(() => {
    getIdRamo(ramo4, setIdRamo4);
  }, [ramo4]);

  const sanitizedHTML = DOMPurify.sanitize(content.course?.descrizione);

  const handleRamo0Click = () => {
    // Naviga alla destinazione con l'id del corso
    navigate(`/corsiList`);
  };

  const handleRamo1Click = () => {
    // Naviga alla destinazione con l'id del corso
    navigate(`/dettagliCorso/${idRamo1.courseId}`);
  };

  const handleRamo2Click = () => {
    // Naviga alla destinazione con l'id del corso
    navigate(`/dettagliCorso/${idRamo2.courseId}`);
  };

  const handleRamo3Click = () => {
    // Naviga alla destinazione con l'id del corso
    navigate(`/dettagliCorso/${idRamo3.courseId}`);
  };

  let numeroRamo = "2";
  if (!content.course?.ramo2) {
    numeroRamo = "2";
  } else if (!content.course?.ramo3) {
    numeroRamo = "3";
  } else if (!content.course?.ramo4) {
    numeroRamo = "4";
  }

  //get ramo
  // const getRamo = async () => {
  //   try {
  //     const response = await client.get(
  //       `/courses/ramo${numeroRamo}/${content.course?.titolo}`
  //     );
  //     setSottoCorsi(response);
  //     setIsLoading(false);
  //   } catch (e) {
  //     console.log(e);
  //   }
  // };
  const getRamo = async () => {
    try {
      const response = await client.get(
        `/courses/ramo${numeroRamo}/${content.course?.titolo}`
      );
      setSottoCorsi(response);
    } catch (e) {
      console.log(e);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    if (content.course?.titolo) {
      getRamo();
    }
  }, [numeroRamo, content.course?.titolo]);

  //LOGICA INVIO RICHIESTA CORSI LIVE

  const inviaEmailCorsoLiveUtente = async () => {
    try {
      const response = await client.get(
        `/inviaEmailCorsoLiveUtente/${session.id}/${content.course?._id}`
      );
    } catch (error) {
      console.log(error);
    }
  };
  const inviaEmailCorsoLiveSYM = async () => {
    try {
      const response = await client.get(
        `/inviaEmailCorsoLiveSYM/${session.id}/${content.course?._id}`
      );
    } catch (error) {
      console.log(error);
    }
  };

  function InviaEmail() {
    const confirmInviaEmail = window.confirm(
      "Una volta inoltrata la tua richiesta, riceverai immediatamente una conferma di ricezione da SymbioTech Store. Successivamente riceverai un'offerta personalizzata, non vincolante, ad un prezzo promozionale dedicato a te, confermare?"
    );

    if (confirmInviaEmail) {
      inviaEmailCorsoLiveSYM();
      inviaEmailCorsoLiveUtente();
    }
  }
  ///////////////

  if (isLoading || !content.course) {
    return (
      <div className="alert-container">
        <PuffLoader color="#ec800f" size={200} />
      </div>
    ); // Mostra un messaggio di caricamento
  }

  // creo una variabile per capire se il corso è un categoria (ramo1) o un corso (ramo 2,3,4)
  // se la get sul corso ID restituisce il nome del ramo2 allora non è una categoria (ramo1)
  // se restituisce false allora è una categoria (ramo1)
  let categoria = content.course?.ramo2;

  return (
    <div className="margin_T100">
      {/* /////////////////////////////////////////////////  SCHERMO GRANDE*/}
      <div className="d-flex flex-wrap align-items-center justify-content-between mb-2 d-none d-md-flex d-xxl-flex">
        <div className="d-flex align-items-center font_percorso ms-3">
          <div
            className="puntatore text_percorso text_m1"
            onClick={handleRamo0Click}
          >
            Corsi
          </div>
          <span className="text_slash mb-1 ms-1">/</span>

          <div
            className={`${
              content.course?.ramo2 ? "puntatore" : ""
            } text_percorso text_m1`}
            onClick={handleRamo1Click}
          >
            {content.course?.ramo1}
          </div>
          {content.course?.ramo2 && (
            <div className=" d-flex align-items-center text_percorso">
              <span className="text_slash mb-1 ms-1">/</span>
              <div
                className={content.course?.ramo3 ? "puntatore" : ""}
                onClick={handleRamo2Click}
              >
                {content.course?.ramo2}
              </div>
              {content.course?.ramo3 && (
                <div className="d-flex align-items-center">
                  <span className="text_slash mb-1 ms-1">/</span>
                  <div
                    className={content.course?.ramo4 ? "puntatore" : ""}
                    onClick={handleRamo3Click}
                  >
                    {content.course?.ramo3}
                  </div>
                  {content.course?.ramo4 && (
                    <div className="d-flex align-items-center text_percorso">
                      <span className="text_slash mb-1 ms-1">/</span>
                      {content.course?.ramo4}
                    </div>
                  )}
                </div>
              )}
            </div>
          )}
        </div>
      </div>
      {/* ///////////////////////////////////////////////// SCHERMO PICCOLO */}
      <div className="d-flex flex-column  align-items-center justify-content-between d-md-none ">
        <div className="d-flex align-items-center font_percorso  d-none d-sm-flex d-md-flex">
          <div
            className={`${
              content.course?.ramo2 ? "puntatore" : ""
            } text_percorso text_m1`}
            onClick={handleRamo1Click}
          >
            {content.course?.ramo1}
          </div>
          {content.course?.ramo2 && (
            <div className=" d-flex align-items-center text_percorso">
              <span className="text_slash mb-1 ms-1">/</span>
              <div
                className={content.course?.ramo3 ? "puntatore" : ""}
                onClick={handleRamo2Click}
              >
                {content.course?.ramo2}
              </div>
              {content.course?.ramo3 && (
                <div className="d-flex align-items-center">
                  <span className="text_slash mb-1 ms-1">/</span>
                  <div
                    className={content.course?.ramo4 ? "puntatore" : ""}
                    onClick={handleRamo3Click}
                  >
                    {content.course?.ramo3}
                  </div>
                  {content.course?.ramo4 && (
                    <div className="d-flex align-items-center text_percorso">
                      <span className="text_slash mb-1 ms-1">/</span>
                      {content.course?.ramo4}
                    </div>
                  )}
                </div>
              )}
            </div>
          )}
        </div>
      </div>

      {/* ///////////////////////////////////////////////// */}
      <div className="text-center">
        {/* IMMAGINE COVER DEL CORSO */}
        {/* <img
          className="imgBox-cover-home mb-3 mt-1"
          src={content.course?.cover}
          alt="cover"
        /> */}
        {!categoria && (
          <img
            className="imgBox-cover-home mb-3 mt-1"
            src={content.course?.cover}
            alt="cover"
          />
        )}
        <Container>
          <div className="text-start ms-5">
            <h1 className="my-4">{content.course?.titolo}</h1>
            <div>
              <div dangerouslySetInnerHTML={{ __html: sanitizedHTML }} />
            </div>
          </div>
        </Container>
        {/* NUOVE AGGIUNTE POST DEPLOY */}
        {session && (
          <>
            {/* SCHERMO GRANDE */}
            <div className="my-5 justify-content-center d-none d-md-flex d-xxl-flex">
              <img
                onClick={() => InviaEmail()}
                className="bannerCorsiLive "
                //style={{ height: "130px", width: "1000px" }}

                src={BannerCorsiLive}
                alt="cover"
              />
            </div>
            {/* SCHERMO PICCOLO */}
            <div className="my-5 justify-content-center d-md-none">
              <img
                onClick={() => InviaEmail()}
                className="bannerCorsiLive w-100"
                //style={{ height: "50px", width: "300px" }}
                style={{ objectFit: "contain" }}
                src={BannerCorsiLive}
                alt="cover"
              />
            </div>

            {/* <div>
              <Button onClick={() => InviaEmail()}>
                Invia da qui la tua richiesta
              </Button>
            </div> */}
          </>
        )}
        <hr className="mt-5 mb-3" />

        {sottoCorsi &&
          isLoading === false &&
          sottoCorsi.course &&
          sottoCorsi.course.length > 0 && <h5>Sotto corsi:</h5>}

        {/* --------------------------------- */}

        {sottoCorsi &&
          isLoading === false &&
          sottoCorsi.course?.map((course) => {
            const renderTooltip = (props) => (
              <Tooltip id="button-tooltip" {...props}>
                {course.titolo}
              </Tooltip>
            );
            return (
              <div key={nanoid()}>
                {/* ---------------------------------------------- SCHERMO GRANDE */}
                <div
                  key={nanoid()}
                  className="cta2 align-items-center mb-4 d-none d-md-flex d-xxl-flex"
                >
                  <div className="cta2_asd d-flex align-items-center ">
                    <a
                      href={`/dettagliCorso/${course._id}`}
                      className="clear_link_black"
                    >
                      <img src={course.cover} alt="cover" />
                    </a>
                  </div>
                  <div className="cta2DivEllipsis ">
                    <a
                      href={`/dettagliCorso/${course._id}`}
                      className="clear_link_black"
                    >
                      <OverlayTrigger
                        placement="top"
                        delay={{ show: 300, hide: 100 }}
                        overlay={renderTooltip}
                      >
                        <p className="ctaEllisse">{course.titolo}</p>
                      </OverlayTrigger>
                    </a>
                  </div>
                </div>

                {/* ---------------------------------------------- SCHERMO PICCOLO */}
                <div
                  key={nanoid()}
                  className="cta2_mobile justify-content-center align-items-center justify-content-between mb-4 d-md-none"
                >
                  <div className="cta2_asd d-flex align-items-center ">
                    <a
                      href={`/dettagliCorso/${course._id}`}
                      className="clear_link_black"
                    >
                      <img src={course.cover} alt="cover" />
                    </a>
                  </div>
                  <div className="cta2DivEllipsis ">
                    <a
                      href={`/dettagliCorso/${course._id}`}
                      className="clear_link_black"
                    >
                      <OverlayTrigger
                        placement="top"
                        delay={{ show: 300, hide: 100 }}
                        overlay={renderTooltip}
                      >
                        <p className="ctaEllisse">{course.titolo}</p>
                      </OverlayTrigger>
                    </a>
                  </div>
                  <div className="cta2_div"></div>
                </div>
              </div>
            );
          })}

        {sottoCorsi &&
          isLoading === false &&
          sottoCorsi.course &&
          sottoCorsi.course.length > 0 && <hr className="mt-3 mb-3" />}

        {/* SCHERMO GRANDE */}
        <div className="my-5 justify-content-center d-none d-md-flex d-xxl-flex">
          <img
            className=" "
            style={{ borderRadius: "20px" }}
            src={BannerPubblCashback}
            alt="cover"
          />
        </div>
        {/* SCHERMO PICCOLO */}
        <div className="my-5 justify-content-center d-md-none">
          <img
            className="w-100"
            //style={{ height: "50px", width: "300px" }}
            style={{ objectFit: "contain" }}
            src={BannerPubblCashback}
            alt="cover"
          />
        </div>
      </div>
    </div>
  );
};

export default CorsoNoLogID;
