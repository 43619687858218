import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import AxiosClient from "../client/client";
import DOMPurify from "dompurify";
import ReactPlayer from "react-player";
import BannerPubblCashback from "../assets/BannerPubblCashback.png";

const ViewVideoWebinar = () => {
  const { id } = useParams();
  const client = new AxiosClient();
  const [video, setVideo] = useState([]);

  const getVideo = async () => {
    const response = await client.get(`/video/${id}`);
    setVideo(response);
  };

  useEffect(() => {
    getVideo();
  }, []);

  const videoUrl = video.video?.video;

  const sanitizedHTML = DOMPurify.sanitize(video.video?.descrizione);

  return (
    <>
      <div className="margin_T100 text-center">
        <h1>{video.video?.titolo}</h1>
        <div className="mt-3">
          <div dangerouslySetInnerHTML={{ __html: sanitizedHTML }} />
        </div>
        {/* <h1>Video:</h1> */}
        <ReactPlayer url={videoUrl} controls width="100%" height="auto" />
        {/* SCHERMO GRANDE */}
        <div className="my-5 justify-content-center d-none d-md-flex d-xxl-flex">
          <img
            className=" "
            style={{ borderRadius: "20px" }}
            src={BannerPubblCashback}
            alt="cover"
          />
        </div>
        {/* SCHERMO PICCOLO */}
        <div className="my-5 justify-content-center d-md-none">
          <img
            className="w-100"
            //style={{ height: "50px", width: "300px" }}
            style={{ objectFit: "contain" }}
            src={BannerPubblCashback}
            alt="cover"
          />
        </div>
      </div>
    </>
  );
};

export default ViewVideoWebinar;
