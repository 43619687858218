import React, { useEffect, useState } from "react";
import AxiosClient from "../client/client";
import { Link, useParams } from "react-router-dom";
import DOMPurify from "dompurify";
import { useNavigate } from "react-router-dom";
import { nanoid } from "nanoid";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Tooltip from "react-bootstrap/Tooltip";
import { Button, Card, Col, Container, Row } from "react-bootstrap";
import { PuffLoader } from "react-spinners";
import BannerPubblCashback from "../assets/BannerPubblCashback.png";

const WebinarNoLogID = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const client = new AxiosClient();

  const [content, setContent] = useState([]);
  const [idRamo1, setIdRamo1] = useState([]);
  const [idRamo2, setIdRamo2] = useState([]);
  const [idRamo3, setIdRamo3] = useState([]);
  const [idRamo4, setIdRamo4] = useState([]);
  const [sottoCorsi, setSottoCorsi] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  const getCont = async () => {
    try {
      const response = await client.get(`/webinar/${id}`);
      setContent(response);
    } catch (error) {
      // Gestisci l'errore qui, ad esempio reindirizzando alla pagina "Not Found"
      //console.error("Errore nella richiesta GET:", error);
      if (error.response && error.response.status === 500) {
        navigate("/pagina-non-trovata"); // Reindirizza alla pagina "Not Found"
      } else {
        // Altrimenti, gestisci l'errore in base alle tue esigenze
        // Potresti mostrare un messaggio all'utente, per esempio
        console.error("Errore sconosciuto:", error);
        // Oppure potresti fare altro
      }
    }
  };

  useEffect(() => {
    getCont();
  }, [id]);

  const ramo1 = content.webinar?.ramo1;
  const ramo2 = content.webinar?.ramo2;
  const ramo3 = content.webinar?.ramo3;
  const ramo4 = content.webinar?.ramo4;

  const getIdRamo = async (ramo, setIdRamo) => {
    if (ramo) {
      const response = await client.get(`/webinarByRamo/${ramo}`);
      setIdRamo(response);
    }
  };

  useEffect(() => {
    getIdRamo(ramo1, setIdRamo1);
  }, [ramo1]);

  useEffect(() => {
    getIdRamo(ramo2, setIdRamo2);
  }, [ramo2]);

  useEffect(() => {
    getIdRamo(ramo3, setIdRamo3);
  }, [ramo3]);

  useEffect(() => {
    getIdRamo(ramo4, setIdRamo4);
  }, [ramo4]);

  const sanitizedHTML = DOMPurify.sanitize(content.webinar?.descrizione);

  const handleRamo0Click = () => {
    // Naviga alla destinazione con l'id del corso
    navigate(`/webinarList`);
  };

  const handleRamo1Click = () => {
    // Naviga alla destinazione con l'id del corso
    navigate(`/dettagliWebinar/${idRamo1.courseId}`);
  };

  const handleRamo2Click = () => {
    // Naviga alla destinazione con l'id del corso
    navigate(`/dettagliWebinar/${idRamo2.courseId}`);
  };

  const handleRamo3Click = () => {
    // Naviga alla destinazione con l'id del corso
    navigate(`/dettagliWebinar/${idRamo3.courseId}`);
  };

  let numeroRamo = "2";
  if (!content.webinar?.ramo2) {
    numeroRamo = "2";
  } else if (!content.webinar?.ramo3) {
    numeroRamo = "3";
  } else if (!content.webinar?.ramo4) {
    numeroRamo = "4";
  }

  //get ramo
  const getRamo = async () => {
    try {
      const response = await client.get(
        `/webinars/ramo${numeroRamo}/${content.webinar?.titolo}`
      );
      setSottoCorsi(response);
    } catch (e) {
      console.log(e);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    if (content.webinar?.titolo) {
      getRamo();
    }
  }, [numeroRamo, content.webinar?.titolo]);

  if (isLoading || !content.webinar) {
    return (
      <div className="alert-container">
        <PuffLoader color="#ec800f" size={200} />
      </div>
    ); // Mostra un messaggio di caricamento
  }

  return (
    <div className="margin_T100">
      {/* /////////////////////////////////////////////////  SCHERMO GRANDE*/}

      <div className="d-flex flex-wrap align-items-center justify-content-between mb-2 d-none d-md-flex d-xxl-flex">
        <div className="d-flex align-items-center font_percorso ms-3">
          <div
            className="puntatore text_percorso text_m1"
            onClick={handleRamo0Click}
          >
            Webinar
          </div>
          <span className="text_slash mb-1 ms-1">/</span>

          <div
            className={`${
              content.webinar?.ramo2 ? "puntatore" : ""
            } text_percorso text_m1`}
            onClick={handleRamo1Click}
          >
            {content.webinar?.ramo1}
          </div>
          {content.webinar?.ramo2 && (
            <div className=" d-flex align-items-center text_percorso">
              <span className="text_slash mb-1 ms-1">/</span>
              <div
                className={content.webinar?.ramo3 ? "puntatore" : ""}
                onClick={handleRamo2Click}
              >
                {content.webinar?.ramo2}
              </div>
              {content.webinar?.ramo3 && (
                <div className="d-flex align-items-center">
                  <span className="text_slash mb-1 ms-1">/</span>
                  <div
                    className={content.webinar?.ramo4 ? "puntatore" : ""}
                    onClick={handleRamo3Click}
                  >
                    {content.webinar?.ramo3}
                  </div>
                  {content.webinar?.ramo4 && (
                    <div className="d-flex align-items-center text_percorso">
                      <span className="text_slash mb-1 ms-1">/</span>
                      {content.webinar?.ramo4}
                    </div>
                  )}
                </div>
              )}
            </div>
          )}
        </div>
      </div>

      {/* ///////////////////////////////////////////////// SCHERMO PICCOLO */}

      <div className="d-flex flex-column  align-items-center justify-content-between d-md-none ">
        <div className="d-flex align-items-center font_percorso  d-none d-sm-flex d-md-flex">
          <div
            className={`${
              content.webinar?.ramo2 ? "puntatore" : ""
            } text_percorso text_m1`}
            onClick={handleRamo1Click}
          >
            {content.webinar?.ramo1}
          </div>
          {content.webinar?.ramo2 && (
            <div className=" d-flex align-items-center text_percorso">
              <span className="text_slash mb-1 ms-1">/</span>
              <div
                className={content.webinar?.ramo3 ? "puntatore" : ""}
                onClick={handleRamo2Click}
              >
                {content.webinar?.ramo2}
              </div>
              {content.webinar?.ramo3 && (
                <div className="d-flex align-items-center">
                  <span className="text_slash mb-1 ms-1">/</span>
                  <div
                    className={content.webinar?.ramo4 ? "puntatore" : ""}
                    onClick={handleRamo3Click}
                  >
                    {content.webinar?.ramo3}
                  </div>
                  {content.webinar?.ramo4 && (
                    <div className="d-flex align-items-center text_percorso">
                      <span className="text_slash mb-1 ms-1">/</span>
                      {content.webinar?.ramo4}
                    </div>
                  )}
                </div>
              )}
            </div>
          )}
        </div>
      </div>

      {/* ///////////////////////////////////////////////// */}

      <div className="text-center">
        <img
          className="imgBox-cover-home mb-3 mt-1"
          src={content.webinar?.cover}
          alt="cover"
        />
        <Container>
          <div className="text-start ms-5">
            <h1 className="my-4">{content.webinar?.titolo}</h1>
            <div>
              <div dangerouslySetInnerHTML={{ __html: sanitizedHTML }} />
            </div>
          </div>
        </Container>
        <hr className="mt-5 mb-3" />

        {sottoCorsi &&
          isLoading === false &&
          sottoCorsi.webinar &&
          sottoCorsi.webinar.length > 0 && (
            <h5 className="mb-4">Sotto corsi:</h5>
          )}

        {/* --------------------------------- */}
        <Container fluid>
          <Row className="d-flex flex-wrap justify-content-center">
            {sottoCorsi &&
              sottoCorsi.webinar?.map((webinar) => {
                const renderTooltip = (props) => (
                  <Tooltip id="button-tooltip" {...props}>
                    {webinar.titolo}
                  </Tooltip>
                );
                return (
                  <Col key={webinar._id} sm={12} md={6} lg={4} xl={3}>
                    <Card className="cardCustom">
                      <Link
                        to={`/dettagliWebinar/${webinar._id}`}
                        className="clear_link_black"
                      >
                        <Card.Img
                          className="imgCard"
                          style={{ width: "18rem", height: "10rem" }}
                          variant="top"
                          src={webinar.cover}
                        />
                        <Card.Body
                          style={{
                            height: "88px",
                          }}
                        >
                          <Card.Title>{webinar.titolo}</Card.Title>
                        </Card.Body>
                      </Link>
                    </Card>
                  </Col>
                );
              })}
          </Row>
        </Container>
        {sottoCorsi &&
          isLoading === false &&
          sottoCorsi.webinar &&
          sottoCorsi.webinar.length > 0 && <hr className="mt-3 mb-3" />}

        {/* SCHERMO GRANDE */}
        <div className="my-5 justify-content-center d-none d-md-flex d-xxl-flex">
          <img
            className=" "
            style={{ borderRadius: "20px" }}
            src={BannerPubblCashback}
            alt="cover"
          />
        </div>
        {/* SCHERMO PICCOLO */}
        <div className="my-5 justify-content-center d-md-none">
          <img
            className="w-100"
            //style={{ height: "50px", width: "300px" }}
            style={{ objectFit: "contain" }}
            src={BannerPubblCashback}
            alt="cover"
          />
        </div>
      </div>
    </div>
  );
};

export default WebinarNoLogID;
// <div key={nanoid()}>
//   {/* ---------------------------------------------- SCHERMO GRANDE */}
//   <div
//     key={nanoid()}
//     className="cta2  align-items-center mb-4 d-none d-md-flex d-xxl-flex"
//   >
//     <div className="cta2_asd d-flex align-items-center ">
//       <a
//         href={`/dettagliWebinar/${webinar._id}`}
//         className="clear_link_black"
//       >
//         <img src={webinar.cover} alt="cover" />
//       </a>
//     </div>
//     <div className="cta2DivEllipsis ">
//       <a
//         href={`/dettagliWebinar/${webinar._id}`}
//         className="clear_link_black"
//       >
//         <OverlayTrigger
//           placement="top"
//           delay={{ show: 300, hide: 100 }}
//           overlay={renderTooltip}
//         >
//           <p className="ctaEllisse">{webinar.titolo}</p>
//         </OverlayTrigger>
//       </a>
//     </div>
//   </div>

//   {/* ---------------------------------------------- SCHERMO PICCOLO */}
//   <div
//     key={nanoid()}
//     className="cta2_mobile justify-content-center align-items-center justify-content-between mb-4 d-md-none"
//   >
//     <div className="cta2_asd d-flex align-items-center ">
//       <a
//         href={`/dettagliWebinar/${webinar._id}`}
//         className="clear_link_black"
//       >
//         <img src={webinar.cover} alt="cover" />
//       </a>
//     </div>
//     <div className="cta2DivEllipsis ">
//       <a
//         href={`/dettagliWebinar/${webinar._id}`}
//         className="clear_link_black"
//       >
//         <OverlayTrigger
//           placement="top"
//           delay={{ show: 300, hide: 100 }}
//           overlay={renderTooltip}
//         >
//           <p className="ctaEllisse">{webinar.titolo}</p>
//         </OverlayTrigger>
//       </a>
//     </div>
//     <div className="cta2_div"></div>
//   </div>
// </div>
